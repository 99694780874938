@import '../../styles/palette';
@import '../../styles/typography';

.tooltip {
    position: relative;
    display: inline-block;

    &:hover {
        .tooltipText {
            visibility: visible;
            opacity: 1;
        }
    }

    .tooltipText {
        @include agBodySmall_13_normal;
        visibility: hidden;
        color: #fff;
        text-align: center;
        white-space: nowrap;
        padding: 4px 8px;
        border-radius: 4px;

        /* Position the tooltip text */
        position: absolute;
        z-index: 1;
        left: 50%;
        transform: translate(-50%, 20%);
        /* Fade in tooltip */
        opacity: 0;
        transition: opacity 0.3s;
        border-style: solid;
        border-width: 0px;

        &::before {
            content: "";
            position: absolute;
            bottom: 100%;
            left: 50%;
            margin-left: -5px;
            border-width: 5px;
            border-style: solid;
        }
    }
}

.default {
    border-color: $gray-800;
    background-color: $gray-800;

    &::before {
        border-color: transparent transparent $gray-800 transparent;
    }
}

.danger {
    border-color: $danger;
    background-color: $danger;

    &::before {
        border-color: transparent transparent $danger transparent;
    }
}

.tooltipPage {
    position: relative;
    display: inline-block;

    &:hover {
        .tooltipPageText {
            visibility: visible;
            opacity: 1;
        }
    }

    .tooltipPageText {
        @include agBodySmall_13_normal;
        visibility: hidden;
        color: #fff;
        text-align: center;
        white-space: normal;
        width: 600px;
        padding: 4px 8px;
        border-radius: 4px;

        /* Position the tooltip text */
        position: absolute;
        z-index: 1;
        left: 50%;
        transform: translate(-40%, 20%);
        /* Fade in tooltip */
        opacity: 0;
        transition: opacity 0.3s;
        border-style: solid;
        border-width: 0px;

        &::before {
            content: "";
            position: absolute;
            bottom: 100%;
            left: 50%;
            margin-left: -5px;
            border-width: 5px;
            border-style: solid;
        }
    }
}