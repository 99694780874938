.wrap {
    margin-bottom: 10px;

    &_label {
        color: #666;
        font-weight: normal;
        font-size: 14px;
        line-height: 20px;
        margin-bottom: 10px;
        white-space: nowrap;
    }
}

.field {
    &_NameKkm {
        grid-column: 1 / 2;
    }
}
