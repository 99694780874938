@import '../../../../styles/global';

.field {
    &_Comment {
        grid-row: 2;
        grid-column: 1/4;
    }
    &_IdModifier {
        grid-row: 3;   
    }
    &_IdEsGroupGlobal {
        grid-row: 3;
    }
}