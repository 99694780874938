.root {
     display: flex;
     flex-direction: row;
    width: 100%;
    //display: grid;
    grid-template-columns: 0fr 0fr 0fr;
    //grid-column-gap: 20px;

    & > div {
        margin-right: 20px;
    }
}

.wrap {
    flex-wrap: wrap;
}