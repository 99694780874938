@import "../../styles/global";

div.dictNormalInline {
    display: flex;
    align-items: center;
}

.errorMessage {
    color: $--color-grid-font-danger;
    font-size: 11px;
    position: static;
}

.inputError {
    &>div>input {
        border-color: $--color-grid-font-danger;
    }
}

div.dictNormal {
    //margin-bottom: 10px;
    border: none;
    padding: 0;

    &>div {
        position: relative;
        //width: 345px;
        margin: 8px 0px 8px 0px;
        width: 100%;
    }

    &>label {
        // Текст подписи к полю выбору из справочника — отсутствует в _colors.scss
        color: #666;
        font-weight: normal;
        font-size: $--font-size-14;
        line-height: $--line-height-20;
        margin-right: 10px;
        white-space: nowrap;

        @include before("xs") {
            font-size: $--font-size-12;
            line-height: $--line-height-18;
        }
    }

    &>div>input {
        border: 1px solid $--color-gray-400;
        border-radius: $--border-radius;
        width: 100%;
        height: 36px;
        padding: 5px 0px 5px 6px;
        margin: 0px;
        font-weight: normal;
        font-size: $--font-size-14;
        line-height: $--line-height-20;
        color: #333;

        @include before("xs") {
            font-size: $--font-size-12;
            line-height: $--line-height-18;
        }

        //        margin-left: 2px;
        outline: none;
        @include corporateFocusedOutline;

        &::placeholder {
            color: #bbbbbb;
        }
    }

    &>div>.dictionary {
        width: 38px;
        height: 36px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 0;
        right: 37px;
        background: none;
        border: 1px solid $--color-gray-400;
        border-left: transparent;
        cursor: pointer;

        &>svg {
            width: 22px;
            height: 22px;
        }
    }

    &>div>input:active~.dictionary:first-child>svg>path {
        stroke: royalblue;
    }

    &>div>.dictionary:last-child {
        background-color: $--color-gray-200;
        border-radius: 0 4px 4px 0;

        top: 0px;
        right: 0px;
        // & > svg {
        //     margin-left: -2px;
        // }
    }
}

.dictNormalInline {
    @extend .dictNormal;
}



div.dictRequiredInline {
    @extend .dictNormal;
    @extend .dictNormalInline;

    &>label {
        font-weight: bold;
    }
}

div.dictRequired {
    @extend .dictNormal;

    &>label {
        font-weight: bold;
    }
}

div.dictRed {
    @extend .dictNormal;

    &>label[for]~div {
        margin-top: 30px;
    }

    &>div>input {
        border: 1px solid $--color-status-negative;
    }
}

.dictGreen {
    @extend .dictNormal;

    &>div>input {
        border: 1px solid $--color-green-300;
    }
}

.dictDisabled {
    &>div>input {
        background-color: $--color-gray-200;
    }

    @extend .dictNormal;
}

.dictDisabledInline {
    display: flex;
    align-items: center;
}

.dictDisabledInline {
    &>div>input {
        background-color: $--color-gray-200;
    }

    @extend .dictNormal;
}

fieldset.dictNormal {
    border: none;
    padding: 0;

    &>div {
        position: relative;
        width: 345px;
        margin: 5px;
    }

    &>label {
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
    }

    &>div>input {
        border: 1px solid $--color-gray-400;
        border-radius: $--border-radius;
        width: 100%;
        padding: 7px 12px 7px 12px;
        margin: 0px;
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
        outline: none;
        @include corporateFocusedOutline;
    }

    &>div.dictionary {
        width: 38px;
        height: 38px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 1px;
        right: 39px;
    }

    &>div.dictionary>svg {
        width: 22px;
        height: 22px;
    }

    &>div>input:active~.dictionary:first-child>svg>path {
        stroke: royalblue;
    }

    &>div.dictionary:last-child {
        background-color: $--color-gray-200;
        border-radius: 0 4px 4px 0;
        border-left: 1px solid $--color-gray-400;
        top: 1px;
        right: 1px;

        &>svg {
            margin-left: -2px;
        }
    }
}