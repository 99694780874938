@import '../../../../styles/global';

.gridTable {
  display: flex;
  flex-direction: column;
  border-radius: $--border-radius;
  position: relative;

  [tabindex] {
    outline: none !important;
  }

  margin-bottom: 10px;
}

.gridColumnsHeader {
  display: flex;
  flex-direction: row;
  position: sticky;
  top: -1px;
  height: 44px;
  z-index: 2;
}

.gridColumnHeaderName {
  font-weight: bold;
}

.gridColumn {
  display: flex;
  flex-direction: row;
  background-color: $--color-gray-250;
  // border: 1px solid $--color-gray-300;
}

.gridColumnCell {
  resize: horizontal;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 9px 16px 9px 16px;
  justify-content: center;
  color: $--color-text-heading;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  box-shadow: inset -1px 0px 0px #dee2e6;
  min-width: 177px;

  @include agBodySmall-medium;

  @include breakpointMedium {
    @include agBodyMedium-medium;
  }

  @include breakpointLarge {
    @include agBodyLarge-medium;
  }

  &[data-filter-selected='true'] {
    background-color: $--color-grid-danger;
  }

  span {
    max-width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}

.gridColumnCell::-webkit-resizer {
  background-color: transparent;
}

.gridColumnCellButtons {
  height: 20px;
  margin-left: 5px;
  display: flex;
  cursor: pointer;

  .gridFilterButton {
    width: 50%;

    svg {
      margin: 4px 4px;
      width: 12px;
      height: 12px;
      fill: $--color-grid-font-danger;
      stroke: $--color-grid-font-danger;
    }
  }

  &[data-sorted='true'] .gridSortButton {
    width: 50%;

    & svg {
      path {
        stroke: red;
      }
    }
  }

  .searchDropDivButton {
    margin-top: -8px;
    margin-left: -5px;
  }
}

.additionalGridColumnCell {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 9px 16px 9px 16px;
  justify-content: center;
  color: $--color-text-heading;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  box-shadow: inset -1px 0px 0px #dee2e6;
  min-width: 177px;
  @include agBodySmall-medium;

  @include breakpointMedium {
    @include agBodyMedium-medium;
  }

  @include breakpointLarge {
    @include agBodyLarge-medium;
  }

  &[data-filter-selected='true'] {
    background-color: $--color-grid-danger;
  }

  span {
    max-width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}

.sortIcon {
  width: 16px;
  height: 19px;

  path {
    stroke-width: 2;
  }

  &_Selected {
    stroke: #006699;

    path {
      stroke-width: 2.5;
    }
  }
}

.gridRowsGroup {
  display: flex;
  flex-direction: column;
  box-shadow: inset 1px 0px 0px #dee2e6;
}

.gridRow {
  display: flex;
  // box-shadow: inset 1px 1px 0px #DEE2E6;
  width: max-content;
}

.gridRowSelected {
  display: inline-flex;
  background-color: $--color-grid-select;
  width: max-content;
}

.gridRowDeleted {
  display: inline-flex;
  background-color: $--color-grid-danger;
  color: $--color-grid-font-danger;

  &.gridRowSelected {
    display: inline-flex;
    background-color: $--color-grid-select;
    color: $--color-grid-font-danger;
  }
}

.gridRowAbsentInGC {
  display: inline-flex;
  background-color: $--color-grid-mark;

  &.gridRowSelected {
    display: inline-flex;
    background-color: $--color-grid-select;
    color: $--color-grid-font-danger;
  }
}

.gridRowCell {
  display: flex;
  align-items: center;
  //background: #FFFFFF;
  height: 36px;
  padding: 0 16px;
  // box-shadow: inset -1px -1px 0px #DEE2E6;
  box-shadow: inset -1px -1px 0px #dee2e6;
  min-width: 177px;

  @include breakpointMedium {
    height: 38px;
  }

  @include breakpointLarge {
    height: 44px;
  }

  >input[type='checkbox'] {
    width: 14px;
    height: 14px;
    accent-color: $--color-primary-100;
  }
}

.gridRowCellText {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @include agBodySmall_normal;

  @include breakpointMedium {
    @include agBodyMedium_normal;
  }

  @include breakpointLarge {
    @include agBodyLarge_normal;
  }
}

.noDataInfo {
  display: flex;
  justify-content: center;
}

.gridWrapperScrollTop {
  height: 20px;
  overflow-x: scroll;
  overflow-y: hidden;

  &::-webkit-scrollbar {
    width: 8px;
    height: 9px;
  }

  &::-webkit-scrollbar-track {
    -webkit-box-shadow: none;
    background-color: transparent;
    border-radius: 30px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 30px;
    background: #adb5bd;
  }
}

.gridWrapper {
  &_quantityPositions {
    margin: auto 0 5px 0;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: #666;
    text-align: left;
  }

  &_right {
    margin-left: auto;
    margin-right: 0;
  }

  &__tables {
    margin: 0;
    max-height: calc(100vh - 293px);
    height: 85%;
    @include double-scroll-bar();
    overflow-y: scroll;

    &__disabledScroll {
      @include double-scroll-bar-hidden();
      overflow-y: hidden;
      color: #666;
      background-color: $--color-gray-100;
    }
  }

  .spin {
    position: fixed;
    top: 55%;
    left: 50%;

    @include breakpointXs {
      left: 60%;
    }

    @include breakpointLarge {
      left: 50%;
    }

    @include breakpointXl {
      left: 40%;
    }
  }
}

.noMouseEvent {
  pointer-events: none;
}

.visibleHook {
  width: 100%;
  height: 1px;
}

.tooltipText {
  display: table;
  width: 100%;
  table-layout: fixed;
  -webkit-user-select: all;
  user-select: all;
}

.defInput {
  background-color: rgba(40, 230, 40, 0.108);
}

.errInput {
  background-color: rgba(243, 26, 15, 0.245);
}