.kizFilteringPanel {
  display: flex;
  align-content: center;
  align-items: center;

  .search {
    flex-basis: 47%;
    max-width: 40%;
  }

  .filterBy {
    margin: auto 10px;
  }

  .autoFilter {
    margin-right: 10px;

    span {
      display: flex;
      align-items: center;
      white-space: nowrap;
    }
  }

  .withinRange {
    margin-right: 10px;

    span {
      display: flex;
      align-items: center;
      white-space: nowrap;
    }
  }
}