
$--line-height-14: 14px;
$--line-height-17: 17px;
$--line-height-18: 18px;
$--line-height-19: 19px;
$--line-height-20: 20px;
$--line-height-24: 24px;
$--line-height-26: 26px;
$--line-height-28: 28px;
$--line-height-30: 30px;
$--line-height-32: 32px;
$--line-height-40: 40px;

$--font-size-12: 12px;
$--font-size-13: 13px;
$--font-size-14: 14px;
$--font-size-15: 15px;
$--font-size-16: 16px;
$--font-size-17: 17px;
$--font-size-18: 18px;
$--font-size-19: 19px;
$--font-size-20: 20px;
$--font-size-22: 22px;
$--font-size-26: 26px;
$--font-size-32: 32px;

$--font-weight-thin: 100;
$--font-weight-light: 300;
$--font-weight-regular: 400;
$--font-weight-medium: 500;
$--font-weight-bold: 700;
$--font-weight-black: 900;

// Ag Body/Small/Small 12
@mixin agBodySmall_normal {
    font-size: $--font-size-12;
    line-height: $--line-height-18;
    font-family: Roboto;
    font-style: normal;
    font-weight: $--font-weight-regular;
}
//Ag Body/Small/Small 12 medium;
@mixin agBodySmall_medium {
    font-size: $--font-size-12;
    line-height: $--line-height-18;
    font-family: Roboto;
    font-style: normal;
    font-weight: $--font-weight-medium;
}


// Ag Body/Small/13/Small normal;
@mixin agBodyMedium_normal {
    font-size: $--font-size-13;
    line-height: $--line-height-19;
    font-family: Roboto;
    font-style: normal;
    font-weight: $--font-weight-regular;
}
// Ag Body/Small/13/Small normal;
@mixin agBodySmall_13_normal {
    font-size: $--font-size-13;
    line-height: $--line-height-19;
    font-family: Roboto;
    font-style: normal;
    font-weight: $--font-weight-regular;
}
//Ag Body/Small/13/Small medium;x
@mixin agBodyMedium_medium {
    font-size: $--font-size-13;
    line-height: $--line-height-19;
    font-family: Roboto;
    font-style: normal;
    font-weight: $--font-weight-medium;
}


// Ag Body/Small/14/Small normal;
@mixin  agBodyLarge_normal {
    font-size: $--font-size-14;
    line-height: $--line-height-20;
    font-family: Roboto;
    font-style: normal;
    font-weight: $--font-weight-regular;
}
//Ag Body/Small/14/Small medium;
@mixin  agBodyLarge_medium {
    font-size: $--font-size-14;
    line-height: $--line-height-20;
    font-family: Roboto;
    font-style: normal;
    font-weight: $--font-weight-medium;
}

/* Heading / H5 */
@mixin  Header_H5 {
    font-size: $--font-size-20;
    line-height: $--line-height-24;
    font-family: Roboto;
    font-style: normal;
}