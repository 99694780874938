.wrapper {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .field {
        &_Number {
            grid-column: 1 / 10;
        }

        &_Date {
            grid-column: 10 / 14;
        }

        &_ToPharmacy {
            grid-column: 1 / 3;
        }

        &_ToStore {
            grid-column: 3/ 6;
        }

        &_GK {
            grid-column: 6 / 9;
        }

        &_DocumentBase {
            grid-column: 1 / 4;
        }

        &_ReasonForMoving {
            grid-column: 4 / 7;
        }

        &_GenerateNewBarcode {
            grid-column: 6 / 7;
        }

        &_SelectionLot {
            width: 70%;
            margin-left: 10px;
            margin-bottom: 0 !important;
            margin-right: 15px;
        }

        &_flexblock {
            display: flex;
            align-items: center;
        }
    }
}

.glwrapper {
    height: 100%;
}

.actionPanel {
    display: flex;
    margin-bottom: 5px;

    .kizAction {
        margin-right: 5px;
    }
}

.scanField {
    opacity: 0;
    height: 0;
    width: 0;
}

.compactFieldFields {
    grid-column: 1 / 35;
}

.compactFieldCompactMode {
    margin-top: 8px;
    grid-column: 35 / 35;
}

.fieldCompactMode {
    margin-top: 8px;
    grid-column: 35 / 35;
}

.labelName {
    border-bottom: 1px solid black;
    width: 60px;
}