@import "../../../../styles/global";

.kizBarcodeButton {
    border-radius: 5px;
    border: 2px solid $--color-gray-300;
    
    button {
        width: 40px;
        height: 33px;
    }
}

.kizBarcodeButtonDisabled {
    opacity: 0.7;
}