.codeControl {
    &_fieldSet {
        border: 1px solid #00000075;
        border-radius: 5px;
        margin-bottom: 5px;
        padding: 10px;
    }
    &_legend {
        width: unset;
        margin-bottom: 0;
        font-size: 1rem;
    }
}