@import "../../../styles/global";

.kiz {
    width: 44px;
    margin-top: 4px;
}

.options {
    width: 190px;
    margin-right: 0px !important;
}

.lot {
    margin-left: 10px;
    margin-right: 15px !important;
}

.settings {
    margin-right: 0px !important;
    margin-top: 8px;
    display: flex;
    width: 36px;
    height: 36px;
    background-color: $--color-gray-250;
    border: 1px solid $--color-gray-500;
    border-radius: $--border-radius;
    align-items: center;
    justify-content: center;
    margin-left: 10px;

    button {
    width: 100%;
    height: 100%;
    }

    svg {
    padding: 0;
    width: 22px;
    height: 22px;
    }

}