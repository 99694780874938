html {
	box-sizing: border-box;
  }

*, *:before, *:after {
	box-sizing: inherit;
}

html,
body {
    margin: 0;
    padding: 0;
    min-height:100%;
    width:100%;
    font-family: Roboto;
}
#root{
    background:#E5E5E5;
    width: 100%;
    height: 100%;
}