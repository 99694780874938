@import '../../../styles/palette';

.common {
    box-sizing: border-box;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    flex-shrink: 0;
}

//STATE
.root {
    cursor: pointer;

    &:hover {
        border-radius: 4px;
    }
}

.disabled {
    cursor: pointer;

    & svg {
        stroke: $secondary-light;
    }
}

//SIZE
.big {
    width: 40px !important;
    height: 40px !important;

    & svg {
        width: 36px !important;
        height: 36px !important;
    }
}

.normal {
    width: 36px !important;
    height: 36px !important;

    & svg {
        width: 24px !important;
        height: 24px !important;
    }
}

.mini {
    width: 33px !important;
    height: 33px !important;

    & svg {
        width: 21px !important;
        height: 21px !important;
    }
}

.micro {
    width: 30px !important;
    height: 30px !important;

    & svg {
        width: 18px !important;
        height: 18px !important;
    }
}

//COLOR
.default {
    & svg {
        stroke: $gray-700;
    }

    &:hover {
        svg {
            stroke: $primary;
        }

        background-color: rgba($primary, 0.1);
    }
}

.info {
    & svg {
        stroke: $info;
    }

    &:hover {
        background-color: rgba($info, 0.1);
    }
}

.danger {
    & svg {
        stroke: $danger;
    }

    &:hover {
        background-color: rgba($danger, 0.1);
    }
}

.dangerDark {
    & svg {
        stroke: $danger-dark;
    }

    &:hover {
        background-color: rgba($danger-dark, 0.1);
    }
}

.success {
    & svg {
        stroke: $success;
    }

    &:hover {
        background-color: rgba($success, 0.1);
    }
}

.calculator {
    z-index: 1000;
    background-color: #ffffff;
    margin: 10px;
    position: absolute;
    left: 45%;
    top: 60px;
    height: 360px;
    width: 240px;
}