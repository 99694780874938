.title {
    display: flex;
    margin-bottom: 10px;
}
.title > span {
    padding-right: 30px;
}
.searchPanel {
    display: flex;
}
.searchGoods {
    width: 90%;
}
.searchTitle {
    margin-top: 15px;
    width: 10%;
}

.searchIcon {
    margin: 8px 0px 0px 8px;
    background-color: #f2f3f7;
    width: 34px;
    height: 36px;
    border-radius: 4px;
}