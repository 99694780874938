.wrapper {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .field {
        &_benefits {
            grid-column: 3/3;
        }

        &_item {
            grid-column: 1/3;
        }

        &_manufacturer {
            grid-column: 1/2;
        }

        &_mnn {
            grid-column: 1/2;
        }

        &_jnvlp {
            grid-column: 1/2;
            margin: 8px 0px;
        }

        &_nameUnderTheContract {
            grid-column: 1/3;
        }

        &_control {
            grid-column: 3/4;
        }

        &_manufacturerContract {
            grid-column: 1/2;
        }

        &_unitMeasurementContract {
            grid-column: 2/3;
        }

        &_edIsm {
            grid-column: 1/2;
        }

        &_price {
            grid-column: 2/3;
        }

        &_quantity {
            grid-column: 3/4;
        }

        &_overDelivery {
            grid-column: 1/2;
        }
        
        &_additionalAgreement {
            grid-column: 2/3;
        }
        
        &_additionalAgreementQuantity {
            grid-column: 3/5;
        }

        &_done {
            grid-column: 2/2;
        }
    }
}