.wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .field {
    &_nameState {
      grid-column: 1/2;
    }

    &_recalculate {
      grid-column: 2/3;
      grid-row: 1 / 2;
    }
  }
}
