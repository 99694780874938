.spinner {
    margin-right: 8px;

    @keyframes spinner {
        0% {
            transform: rotate(0deg);
        }

        100% {
            transform: rotate(359deg);
        }
    }

    display: inline-block;
    width: 20px;
    height: 20px;
    border: 0.15em solid #006699;
    border-right-color: transparent;
    border-radius: 50%;
    animation: spinner 0.75s both infinite;
}

.xlSpinner {
    margin-right: 8px;

    @keyframes spinner {
        0% {
            transform: rotate(0deg);
        }

        100% {
            transform: rotate(359deg);
        }
    }

    display: inline-block;
    width: 70px;
    height: 70px;
    border: 0.15em solid #006699;
    border-right-color: transparent;
    border-radius: 50%;
    animation: spinner 0.75s both infinite;
}

.wrapper {
    display: flex;
    justify-content: center;
    align-items: center;

}