@import "../../../styles/global";

.tabsPanelWorkarea {
    padding: 10px 16px;
    background-color: #fff;
    border-radius: $--border-radius;
    box-shadow: 0px 0px 10px rgba(73, 80, 87, 0.07);
    margin-bottom: 5px;
    margin-top: 16px;
    height: 100%;
    max-height: calc(100% - 76px);
    position: relative;
    //border-bottom: 2px solid $--color-gray-300;

    .tabsPanelWrapper {
        margin: 0 0 11px;
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .tabsContainer {
            position: relative;
            overflow: hidden;
            width: 100%;
        }
    }
}