@mixin customMargin {
    > div {
        margin: 8px 0px 8px 0px !important;
    }
}

.wrapper {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .firstRow {
        display: flex;
        justify-content: flex-start;

        .balance {
            margin-top: 20px;
            margin-right: 10px;
        }

        .refPrice {
            margin-right: 10px;
            margin-top: 20px;
        }

        .code {
            width: 60%;
            margin-right: auto;
        }

        .date {
            margin-left: 10px;
            width: auto;
        }



    }

    .field {
        &_balance {
            grid-column: 2/3;
            left: 0;
        }

        &_refPrice {
            grid-column: 3/5;
        }

        &_code {
            grid-column: 5/7;
        }

        &_date {
            grid-column: 7/8;
        }

        &_client {
            grid-column: 1/4;
            @include customMargin
        }

        &_dateBegin {
            grid-column: 4/5;
        }

        &_dateEnd {
            grid-column: 5/6;
        }

        &_lotNumber {
            grid-column: 1/2;
        }

        &_lotWinner {
            grid-column: 2/4;
            @include customMargin
        }

        &_protocol {
            grid-column: 1/2;
        }

        &_dateProtocol {
            grid-column: 2/4;
        }

        &_selectGoods {
            grid-column: 1/5;
        }

        &_searchOption {
            margin-top: 30px;
            grid-column: 5/6;
        }

        &_taskProgram {
            @include customMargin
        }
    }

    .panel {
        margin-bottom: 10px;
    }

    .grid {
        margin-bottom: 10px;
    }
}

.goodsSelectorModalContainer {
    align-items: end;
}

.nameContractMB  {
    margin-bottom: 8px !important;
    
    > div{
        margin-bottom: 0 !important;
    }
}
