.buttonBlock {
    display: flex;
    justify-content: space-between;
}

.button {
    width: 240px;
}

.kizButton {
    width: 180px;
}