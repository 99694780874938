@import "../../styles/mixins";
@import "../../styles/colors";
@import "../../styles/global";

.checkBox {
    margin-top: 5px;
    display: flex;
    flex-direction: row;
    align-items: center;

    &>input {
        width: 14px;
        height: 14px;
        border: 1.5px solid #6c757d;
        box-sizing: border-box;
        border-radius: 2px;
        margin: 0px 6px;
        accent-color: $--color-primary-100;

        &:checked {
            margin: 0px 6px;
          }
        
        &:focus {
            opacity: 1;
        }
    }

    &>label {
        color: #333333;
    }
}