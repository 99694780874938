@import '../../styles/global';

.checkboxContainer {
  display: inline-block;
  vertical-align: middle;
}

.styledCheckbox {
  display: inline-block;
  width: 14px;
  height: 14px;
  background: "salmon";
  border-radius: 3px;
  transition: all 150ms;
}

.checkbox {
  width: 14px;
  height: 14px;
  border: 1.5px solid #6c757d;
  box-sizing: border-box;
  border-radius: 2px;
  margin: 0px 6px;
  accent-color: $--color-primary-100;

  &:checked {
    margin: 0px 6px;
  }

  &:focus {
    opacity: 1;
  }
}

.label {
  color: #333333;
}