@import '/src/styles/global';

.flexColumn {
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: #f9f9f9;
    padding: 0.5%;
}

.rowDanger {
    display: inline-flex;
    background-color: $--color-grid-danger;
    color: $--color-grid-font-danger;

    &__gridRowSelected {
        display: inline-flex;
        background-color: $--color-grid-select;
        color: $--color-grid-font-danger;
    }
}

.flexRow {
    width: 35%;
    margin-left: auto; 
    margin-right: 0.8%;
}

.numberInput {
    width: 15%;
}

.button {
    margin: 1%;
}