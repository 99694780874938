@import "../styles/global";

.menuWrapper {
    width: 250px;
    height: 100vh;
    background: $--color-corporate-600;
    display: flex;
    flex-direction: column;
    position: fixed;
    flex-shrink: 0;
    z-index: 10;

    .logoWrapper {
        margin: 30px 24px 35px;
        display: block;

        svg {
            width: 102px;
            height: 42px;
        }
    }

    @include before("lg") {
        width: 230px;
    }
}

.info {
    display: flex;
    padding: 0 20px 0 15px;
    margin-top: 10px;
    flex-direction: column;
    word-break: break-word;
    font-size: $--font-size-16;
    line-height: $--line-height-20;

    .login {
        color: $--color-gray-300;
    }

    .role {
        color: #6C757D;
    }
}

.panelHide {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    width: 34px;
    height: 34px;
    right: 20px;
    top: 34px;
    cursor: pointer;
    background-color: none;
    border-radius: $--border-radius;
    transition: $--transition-settings;

    &:hover,
    &:focus {
        background-color: rgba(255, 255, 255, 0.2);
        transition: $--transition-settings;
    }
}

.MenuItems {
    display: flex;
    flex-direction: column;
    list-style: none;
    padding: 0;
    margin: 0;
}

.bottomNavigationWrapper {
    margin-top: auto;
    display: flex;
    flex-direction: column;
}

.mainMenuItemWrapper {
    position: relative;
    display: flex;
    flex-direction: row;
    margin-bottom: 8px;
}

.navItem {
    width: 100%;
    display: flex;
    align-items: center;
}

.selectedBackgroundMenu {
    background-color: rgba(255, 255, 255, 0.1);

    &::before {
        content: "";
        height: 100%;
        width: 6px;
        border-radius: 10px 0px 0px 10px;
        background-color: $--color-corporate-500;
        position: absolute;
        top: 0;
        right: 0;
        opacity: 1;
        transition: $--transition-settings;
    }
}

.navLink {
    padding: 8px 16px;
    display: block;
    width: 100%;
    font-size: $--font-size-20;
    line-height: $--line-height-24;
    cursor: pointer;
    color: $--color-gray-300;
    transition: $--transition-settings;
    position: relative;

    &:hover,
    &:focus {
        background-color: rgba(255, 255, 255, 0.1);
        transition: $--transition-settings;

        &::after {
            opacity: 1;
            transition: $--transition-settings;
        }
    }

    &::after {
        content: "";
        height: 100%;
        width: 6px;
        border-radius: 10px 0px 0px 10px;
        background-color: $--color-corporate-500;
        position: absolute;
        top: 0;
        right: 0;
        opacity: 0;
        transition: $--transition-settings;
    }

    @include before("lg") {
        font-size: $--font-size-16;
        line-height: $--line-height-20;
        padding: 10px 24px;
    }
}

.navLinkSub {
    @extend .navLink;
    color: $--color-text-heading;
    margin-bottom: 6px;
    border-radius: $--border-radius;
    font-size: $--font-size-14;
    line-height: $--line-height-20;
    white-space: break-spaces;

    &:hover,
    &:focus {
        background-color: rgba(242, 243, 247, 1);
    }

    &::after {
        display: none;
    }

    &Active {
        @extend .navLinkSub;
        background-color: transparentize($--color-primary-50, 0.8);

        &:hover,
        &:focus {
            background-color: transparentize($--color-primary-50, 0.8);
            cursor: default;
        }
    }

    @include before("lg") {
        font-size: $--font-size-14;
        line-height: $--line-height-20;
        padding: 8px 24px;
        margin-bottom: 6px;
    }

    @include before("md") {
        font-size: $--font-size-12;
        line-height: $--line-height-18;
        padding: 6px 24px;
        margin-bottom: 8px;
    }
}

.subMenu {
    background: #ffffff;
    box-shadow: 20px 0px 20px rgba(73, 80, 87, 0.3);
    border-radius: $--border-radius;
    position: fixed;
    top: 0;
    left: 250px;
    width: 250px;
    height: 100%;
    color: #333333;
    padding: 0 10px 0 10px;
    z-index: 2;
    overflow: auto;
    user-select: none;
    display: flex;
    flex-flow: column;
    counter-reset: items;

    @include before("lg") {
        left: 230px;
    }

    @include before("md") {
        left: 230px;
    }
    
    @include scroll-bar-subMenu()
}

.subMenuItem {
    display: flex;
    max-width: 192px;
    width: 100%;
    white-space: nowrap;
    flex-wrap: wrap;

    &:nth-of-type(5n + 1) {
        order: 1;
    }

    &:nth-of-type(5n + 2) {
        order: 2;
    }

    &:nth-of-type(5n + 3) {
        order: 3;
    }

    &:nth-of-type(5n + 4) {
        order: 4;
    }

    &:nth-of-type(5n) {
        order: 5;
    }
}

.subMenuUl {
    list-style: none;
    padding: 0;
    margin: 0;
    height: auto;
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 192px;

    >li {
        max-width: 192px;
    }

    &::after {
        content: "";
        height: 1px;
        width: 192px;
        background-color: $--color-gray-500;
        margin: 20px auto 0;
    }
}


.subMenuTitle {
    color: $--color-primary-200;
    padding: 8px 16px 18px;
    font-size: $--font-size-18;
    margin: 0;

    @include before("lg") {
        font-size: $--font-size-18;
        line-height: $--line-height-20;
    }

    @include before("md") {
        font-size: $--font-size-16;
        line-height: $--line-height-20;
    }
}

.SearchBlock {
    display: inline-block;
    justify-content: center;
    margin: 10px;

    .SearchForm {
        position: relative;
        max-width: 230px;
        width: 100%;
    
        @include before("lg") {
            max-width: 210px;
        }

        .SearchInput {
            border-radius: $--border-radius;
            background-color: $--color-corporate-700;
            padding: 8px 12px;
            border: none;
            font-size: $--font-size-20;
            line-height: $--line-height-24;
            color: #fff;
            width: 100%;
        
            &::placeholder {
                color: $--color-gray-300;
            }
        
            &:focus {
                outline: none;
                border: none;
                box-shadow: 0px 0px 0px 3.2px transparentize($--color-primary-300, 0.6);
            }
        
            @include before("lg") {
                font-size: $--font-size-16;
                line-height: $--line-height-20;
            }
        }

        .SearchButton {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: 12px;
            cursor: pointer;
            width: 16px;
            height: 16px;
            padding: 0;
            background-color: inherit;
        
            &::before {
                @include click-area();
            }

            .searchIcon {
                width: 16px;
                height: 16px;
            
                path {
                    stroke-width: 2;
                    stroke: #ffffff;
                }
            }
        }
    }
}

.solid {
    margin: 10px 0 20px;
    height: 1px;
    background-color: $--color-gray-600;
    width: 100%;
    display: block;

    &Sub {
        @extend .solid;
        background-color: $--color-gray-500;
        margin: 3px 24px 13px;
        width: auto;
        max-width: 176px;
        display: none;

        @include before("lg") {
            margin: 4px 24px 11px;
        }

        @include before("md") {
            margin: 1px 24px 13px;
        }
    }
}

.exitBtn {
    font-size: $--font-size-20;
    line-height: $--line-height-24;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 13px 24px;
    color: #fff;
    background: rgba(255, 255, 255, 0.1);
    cursor: pointer;
    margin-top: 20px;
    transition: $--transition-settings;

    span {
        margin-left: 14px;
    }

    svg {
        transition: $--transition-settings;

        path {
            fill: none;
            stroke: white;
        }
    }

    &:hover,
    &:focus {
        color: $--color-corporate-500;

        svg {
            transition: $--transition-settings;

            path {
                stroke: $--color-corporate-500;
            }
        }
    }

    @include before("lg") {
        font-size: $--font-size-16;
        line-height: $--line-height-20;
        padding: 15px 24px;
        margin-top: 10px;
    }
}

.SearchResultBlock {
    background: hsl(224, 38%, 14%);
    border-radius: $--border-radius;
    margin-top: 5px;

    ul {
        padding: 7px 0;
    }

    li {
        padding: 7px 24px;
        font-family: Roboto;
        font-style: normal;
        font-weight: normal;
        font-size: $--font-size-14;
        line-height: $--line-height-20;
        color: #adb5bd;

        &:hover {
            background-color: rgba(255, 255, 255, 0.1);
            transition: all 0.3s ease;
        }
    }
}

.searchResultUl {
    list-style: none;
    padding: 0;
    margin: 0;
}

.itemMenu {
    display: flex;
    justify-content: space-between;
    user-select: none;
}

.crossIcon path {
    stroke: red;
    stroke-width: 2px;
}

.panelHideIcon {
    path {
        stroke: white;
    }
}

.cross {
    position: sticky;
    top: 0;
    display: flex;
    justify-content: end;
    cursor: pointer;
}