.modalCreator {
    .field {
        &_Goods {
            grid-column: 1 / 1;
        }
        &_GoodsName {
            grid-column: 2 / 16;
        }
    }
}
