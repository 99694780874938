@import '../../../../../styles/global';

.gridTable {
    display: flex;
    flex-direction: column;
    border-radius: $--border-radius;
    position: relative;

    [tabindex] {
        outline: none !important;
    }

    margin-bottom: 10px;
}

.gridColumnsHeader {
    display: flex;
    flex-direction: row;
    position: sticky;
    top: -1px;
    height: 44px;
}

.gridColumn {
    display: flex;
    flex-direction: row;
    background-color: $--color-gray-250;
    // border: 1px solid $--color-gray-300;
}

.gridColumnCell {
    resize: horizontal;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 9px 16px 9px 16px;
    justify-content: space-between;
    color: $--color-text-heading;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    box-shadow: inset -1px 0px 0px #dee2e6;
    min-width: 177px;

    @include agBodySmall-medium;

    @include breakpointMedium {
        @include agBodyMedium-medium;
    }

    @include breakpointLarge {
        @include agBodyLarge-medium;
    }

    &[data-filter-selected="true"] {
        background-color: $--color-grid-danger;
    }

    span {
        max-width: 100%;
        text-overflow: ellipsis;
        overflow: hidden;
    }
}

.gridColumnCell::-webkit-resizer {
    background-color: transparent;
}

.gridColumnCellButtons {
    height: 20px;
    display: flex;
    cursor: pointer;

    .gridFilterButton {
        width: 50%;

        svg {
            margin: 4px 4px;
            width: 12px;
            height: 12px;
            fill: $--color-grid-danger;
            stroke: $--color-grid-font-danger;
        }
    }

    &[data-sorted="true"] .gridSortButton {
        width: 50%;

        & svg {
            path {
                stroke: red;
            }
        }
    }

    .searchDropDivButton {
        margin-top: -8px;
        margin-left: -5px;
    }
}

.sortIcon {
    width: 16px;
    height: 19px;

    path {
        stroke-width: 2;
    }

    &_Selected {
        stroke: #006699;

        path {
            stroke-width: 2.5;
        }
    }
}

.gridRowsGroup {
    display: flex;
    flex-direction: column;
    box-shadow: inset 1px 0px 0px #dee2e6;
}

.gridRow {
    display: flex;
    // box-shadow: inset 1px 1px 0px #DEE2E6;
    width: max-content;
}

.gridRowSelected {
    display: inline-flex;
    background-color: $--color-grid-select;
    width: max-content;
}

.gridRowDeleted {
    display: inline-flex;
    background-color: $--color-grid-danger;
    color: $--color-grid-font-danger;

    &.gridRowSelected {
        display: inline-flex;
        background-color: $--color-grid-select;
        color: $--color-grid-font-danger;
    }
}

.gridRowSuccess {
    display: inline-flex;
    box-shadow: inset -1px -1px 0px $--color-grid-success;
    //border: 1px solid $--color-grid-success;

    &.gridRowSelected {
        display: inline-flex;
        background-color: $--color-grid-select;
        color: $--color-grid-font-succcess;
    }
}

.gridRowGoodsCode {
    background-color: $--color-grid-success;
}

.gridRowBarcode {
    background-color: $--color-grid-mark;
}

.gridRowHandBunch {
    background-color: $--color-grid-mark-light;
}

.gridRowCell {
    display: flex;
    align-items: center;
    //background: #FFFFFF;
    height: 36px;
    padding: 0 16px;
    // box-shadow: inset -1px -1px 0px #DEE2E6;
    box-shadow: inset -1px -1px 0px #dee2e6;
    min-width: 177px;

    @include breakpointMedium {
        height: 38px;
    }

    @include breakpointLarge {
        height: 44px;
    }

    >input[type="checkbox"] {
        width: 14px;
        height: 14px;
        accent-color: $--color-primary-100;
    }
}

.gridRowCellSuccess {
    display: flex;
    align-items: center;
    //background: #FFFFFF;
    height: 36px;
    padding: 0 16px;
    // box-shadow: inset -1px -1px 0px #DEE2E6;
    box-shadow: inset -1px -1px 0px $--color-grid-success;
    min-width: 177px;

    @include breakpointMedium {
        height: 38px;
    }

    @include breakpointLarge {
        height: 44px;
    }

    >input[type="checkbox"] {
        width: 14px;
        height: 14px;
    }
}

.gridRowCellText {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    @include agBodySmall_normal;

    @include breakpointMedium {
        @include agBodyMedium_normal;
    }

    @include breakpointLarge {
        @include agBodyLarge_normal;
    }

    >input[type="checkbox"] {
        width: 14px;
        height: 14px;
        accent-color: $--color-primary-100;
    }
}

.outWrapper {
    display: flex;
    flex-direction: column;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.noDataInfo {
    display: flex;
    justify-content: center;
}

.gridWrapper {
    &__quantityPositions {
        font-weight: normal;
        font-size: 14px;
        line-height: 20px;
        margin: 10px 0;
        color: #666;
    }

    &__checkbox {
        margin: 6px 0;
    }

    &__tables {
        margin: 0;
        max-height: calc(100vh - 500px);
        height: 100%;
        @include double-scroll-bar();
    }
}

.noMouseEvent {
    pointer-events: none;
}