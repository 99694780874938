.field {
    &_NameRus {
        grid-column: 1/2;
    }
    &_NameLat {
        grid-column: 2/2;
    }
    &_Mnemocode {
        grid-column: 1/2;
    }
    &_FederalCode {
        grid-column: 2/2;
    }
}
