// custom styles


.flex__block {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    & > div {
        flex-basis: 100%;
    }
}

.label__width {

    &-64 {
        min-width: 64px;
    }

    &-76 {
        min-width: 76px;
    }

    &-111 {
        min-width: 111px;
    }
}