.field {
    &_Name {
        align-items: center;
        align-self: center;
        grid-column: 1 / 1;
    }

    &_NameValue {
        grid-column: 2/ 6;
    }
}

.btn {
    display: inline-block;
    box-sizing: border-box;
    outline: none;
    margin: 0 5px 15px 0;
    min-width: 90px;
    height: 30px;
    font-size: 14px;
    text-decoration: none;
    color: #444;
    cursor: pointer;
    user-select: none;
    appearance: none;
    touch-action: manipulation;
    vertical-align: top;
    transition: box-shadow 0.2s;

    :focus-visible {
        border: 1px solid #4c51f9;
        outline: none;
    }

    :hover {
        transition: all 0.2s;
    }

    :active {
        background-color: #808080;
    }

}

.pricingSelector {
    &_selectorInput {
        grid-column: 2 / 6;
    }
}