.field {
    &_Search {
        grid-column: 1 / 3;
    }

    &_SelectBlock {
        margin-top: 8px;
        grid-column: 3/5;
    }
}

.disabledbutton {
    pointer-events: none;
    opacity: 0.4;
}