@import "../../../../styles/mixins";

.contractorEditorModalWindow {
    width: 800px;
}

.legalEntityEditorModalWindow {
    width: 1000px;
}

.contractorEditor {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &>.contractorPanel {
        width: 100%;

        & .charsRow {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            width: 100%;

            &>div {
                flex-grow: 0.01;
                margin-right: 20px;
            }

            &>div:last-child {
                margin-right: 0px;
            }

            &>div:first-child {
                flex-grow: 1;
            }
        }

        & .invoiceRow,
        .invoiceFileRow,
        .legalEntity,
        .contractorAdditional,
        .contractorKiz {
            @include twoRowDivs();
        }

        & .contractorContacts {
            display: flex;
            flex-direction: column;

            &>div:first-child {
                @include twoRowDivs();
                margin-right: 0px;
            }

            &>div:last-child {
                width: 100%;
            }
        }

    }
}

.contractorChars,
.contractorInvocies,
.contractorAdditional,
.contractorKiz {
    display: block;
}

.td
{
    padding-left: 10px;
}

.contractorContacts {
    display: block;
}

.contractorGroups>div {
    max-height: 400px;
}

.field {
    &_ImportProvider {
        grid-column: 1/4;
    }

    &_InvoiceImportMappingModel {
        grid-column: 4/8;
    }

    &_SettingsButton {
        margin-top: 30px;
        grid-column: 8/8;
    }
}

.fieldLegal {
    &_Entity {
        grid-column: 1/8;
    }
    &_Button {
        margin-top: 30px;
        grid-column: 8/8;
    }
}