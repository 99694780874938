@import "../../styles/global";

.contextMenu {
    @include agBodySmall_normal();
    display: block;
    position:absolute;
    background-color: white;
    border-radius: $--border-radius;
    z-index: 2;
    padding-top: 5px;
    padding-bottom: 5px;
    box-shadow: 0px 0px 20px rgba(73, 80, 87, 0.3);

    &>ul>li {
        padding: 5px 10px;

        button {
            background-color: transparent;

            &:disabled {
                color: $--color-gray-500;
            }
        }
    }

    &>ul>li:hover {
        background-color: $--color-gray-250;
    }
}

.contextMenuList {
    list-style-type: none;
    margin: 0;
    padding: 0;
}