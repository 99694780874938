.lotsFilteringPanel {
  display: flex;
  align-content: center;
  align-items: center;

  .inlineInput {
    max-height: 34px;
  }

  .search {
    flex-basis: 47%;
    margin-left: -1%;
    max-height: 34px;
  }

  .filterBy {
    flex-basis: 20%;
    margin-right: 20px;
    max-height: 34px;
    margin-bottom: 20px;
  }

  .autoFilter {
    flex-basis: 12%;
    margin-right: 20px;
    max-height: 34px;
    margin-bottom: 9px;
  }

  .withinRange {
    flex-basis: 12%;
    margin-right: 20px;
    width: 100%;
    max-height: 34px;
    margin-bottom: 9px;
  }

  .filterButton {
    flex-basis: 9%;
    margin-left: auto;
    max-height: 34px;
    margin-bottom: 10px;
  }
}

.width100 {
  width: 100%;
  padding-bottom: 0;
}


.lotsFilteringTwo {
  display: flex;
  align-items: center;


  .additionalFilter {
    display: flex;
    flex-basis: 20%;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-bottom: 0;
    margin-top: 0;
    margin-right: 20px;
  }

  .storeFilter {
    display: flex;
    flex-basis: 20%;
    align-items: center;
    margin-bottom: 0;
    margin-top: 0;
    margin-right: 20px;
  }

  .pharmacyFilter {
    display: flex;
    flex-basis: 20%;
    align-items: center;
    margin-bottom: 0;
    margin-top: 0;
    margin-right: 20px;
  }

  .providerFilter {
    display: flex;
    flex-basis: 20%;
    align-items: center;
    margin-bottom: 0;
    margin-top: 0;
    margin-right: 20px;
  }

  .remainsOnlyFilter {
    display: flex;
    flex-basis: 12%;
    align-items: center;
    margin-left: auto;
    margin-bottom: 0;
    margin-top: 0;
  }
}

.lotsFilteringThree {
  display: flex;
  align-items: center;
  padding-bottom: 5px;
  justify-content: space-between;

  .clearFilter {
    width: 10%;
  }

  .lotsFilteringThreeWrapper {
    display: flex;
    align-items: center;
    padding-bottom: 5px;

    .periodByFilter {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      margin-right: 20px;
      margin-bottom: 0;
      margin-top: 0;
    }

    .datePeriodFilter {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      margin-right: 20px;
      margin-bottom: 0;
      margin-top: 0;
    }



    .balanceOffBalanceFilter {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      margin-right: 20px;
      margin-bottom: 0;
      margin-top: 0;
    }

    .IZFilter {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      margin-right: 20px;
      margin-bottom: 0;
      margin-top: 0;
    }

    .OAFilter {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      margin-bottom: 0;
      margin-top: 0;
    }
  }

}