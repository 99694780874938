@mixin click-area() {
    content: "";
    position: absolute;
    display: block;
    top: -5px;
    left: -5px;
    right: -5px;
    bottom: -5px;
    cursor: pointer;
}

@mixin scroll-bar() {
    overflow-y: scroll;
    scrollbar-color: #adb5bd transparent;
    scrollbar-width: thin;

    &::-webkit-scrollbar {
        width: 8px;
        height: 6px;
    }

    &::-webkit-scrollbar-track {
        -webkit-box-shadow: none;
        background-color: transparent;
        border-radius: 30px;
    }

    &::-webkit-scrollbar-thumb {
        border-radius: 30px;
        background: #adb5bd;
    }
}

@mixin scroll-bar-subMenu() {
    overflow-y: scroll;
    scrollbar-color: #adb5bd transparent;
    scrollbar-width: thin;

    &::-webkit-scrollbar {
        width: 8px;
        height: 6px;
    }

    &::-webkit-scrollbar-track {
        -webkit-box-shadow: none;
        background-color: transparent;
        border-radius: 30px;
    }

    &::-webkit-scrollbar-thumb {
        border-radius: 30px;
        background: $--color-corporate-600;
    }
}

@mixin double-scroll-bar() {
    overflow-x: auto !important;
    overflow-y: scroll !important;
    scrollbar-color: #adb5bd transparent;
    scrollbar-width: thin;

    &::-webkit-scrollbar {
        width: 8px;
        height: 9px;
    }

    &::-webkit-scrollbar-track {
        -webkit-box-shadow: none;
        background-color: transparent;
        border-radius: 30px;
    }

    &::-webkit-scrollbar-thumb {
        border-radius: 30px;
        background: #adb5bd;
    }
}

@mixin double-scroll-bar-hidden() {
    overflow-x: auto !important;
    overflow-y: hidden !important;
    scrollbar-color: #adb5bd transparent;
    scrollbar-width: thin;

    &::-webkit-scrollbar {
        width: 8px;
        height: 9px;
    }

    &::-webkit-scrollbar-track {
        -webkit-box-shadow: none;
        background-color: transparent;
        border-radius: 30px;
    }

    &::-webkit-scrollbar-thumb {
        border-radius: 30px;
        background: #adb5bd;
    }
}

@mixin corporateFocusedOutline() {
    &:focus {
        outline: none;
        box-shadow: 0px 0px 0px 3px #99ccff;
    }
}

@mixin twoRowDivs() {
    display: flex;
    flex-direction: row;
    padding-right: 0;

    &>div {
        width: 50%;
        margin-right: 20px;
    }

    &>div:last-child {
        margin-right: 0;
    }
}