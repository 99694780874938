.container {
    height: 100%;

    .wrapper {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
}

.addIventPanel {
    grid-template-columns: 40% auto;
}

.accountingLag {
    width: 10%;
}

.correctModalWindow {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.typeSources {
    width: 100%;
}