.margin20 {
    margin-left: 20px;
    margin-right: 20px;
}

.creatorWrapper {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.width300px {
    width: 300px;
}

.width100pc {
    width: 100%;
}

.lotsFilterPanel {
    margin-top: -10px;
    margin-bottom: 10px;
}
