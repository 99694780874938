.wrapper {
    display: flex;
}
.titles {
    display: flex;
    flex-direction: column;
}
.title {
    margin-left: 10px;
}
.check {
    justify-content: center;
}

.select {
    width: 25%;
}
