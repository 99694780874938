.datePeriodPanel {

  &__label {
    color: #666;
    margin-right: 10px;
  }

  &__wrapper {
    display: flex;
  }

  &__period {
    margin-left: 10px;
  }
}

.datePeriodInline {
  display: flex;
  align-items: center;
}

.select {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Roboto, Roboto, Roboto, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-size: 15px;
}