@import '../../styles/global';

.gridPaginator {
    display: flex;
    flex-direction: row;

    @include agBodyMedium_normal;

    .pageBtn {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 7px 9px;
        width: 25px;
        height: 25px;
        background: #F2F3F7;
        border-radius: 50px;
        margin: 0;
        cursor: pointer;

        &:hover,:active  {
            background-color: #9cf;
            path {    
                stroke: #FFFFFF;
            }
        }
        
        

        @include breakpointMedium{
            width: 30px;
            height: 30px;
        }
    }

    ul {
        display:flex;
        flex-direction: row;
        height: 27px;
        border-radius: 30px;
        padding: 1px;
        background: rgba(242, 243, 247, 1);
        list-style: none;
        margin: 0 6px;

        @include breakpointMedium {
            height: 30px;
            padding: 0px 1px 0px 1px;
        }

        li {
            cursor: pointer;
            width: 25px;
            height: 25px;
            display: flex;
            align-items: center;
            justify-content: center;

            @include breakpointMedium {
                height: 30px;
                width: 30px;
            }

            &[data-current-page="true"] {
                background: #006699;
                border-radius: 50px;
                color: #FFFFFF;

            }

            &:not([data-current-page="true"]):hover {
                background-color: #9cf;
                border-radius: 50%;
                color:white;
            }
        }
    }
    align-items: center;

    &>span{
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-left: 20px;
        &>select{
            width: auto;
            margin-left: 10px;
            height: 32px;
            font-size: 14px;
            min-width: 60px;
        }
    }
}

.gridPaginatorUl {
    list-style: none;
    margin: 0;
    padding: 0;
}
.disabled {
    pointer-events:none;
    opacity:0.6;
}