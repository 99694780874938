@import "../../styles/_variables";
@import "../../styles/_global";

.upgradePatchActionWrapper {
    width: 100%;
    height: 100vh;
    background-color: $corporate-D7DDEB;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0.8;
    position: fixed;
    z-index: 11;
}

.upgradePatchAction {
    max-width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &__image {
        width: 500px;
        height: 500px;
        background-image: url(../../libs/corporate/icons/img/Upgrade-bro.svg);
    }

    &__title {
        display: flex;
        text-align: center;
        font-size: $--font-size-32;
        color: $corporate-34495E;

        div {
            width: 29px;
        }

        .ellipsis {
            overflow: hidden;
            display: inline-block;
            vertical-align: bottom;
            animation: ellipsis 1s infinite;
        }
    }

    @media (max-width: 1280px) {
        &__image {
            width: 300px;
            height: 300px;
        }

        &__title {
            font-size: $--font-size-22;
        }
    }
}
 
@keyframes ellipsis {
    from {
        width: 2px;
    }
    to {
        width: 29px;
    }
}