$corporate-10: #039bb3;
$corporate-20: #337ab7;
$corporate-30: #34495e;
$corporate-40: #283046;
$leftMenu: #283046;

$primary: #006699;
$primary-dark: #336699;
$primary-light: #99ccff;

$secondary: #6c757d;
$secondary-dark: #54595e;
$secondary-light: #abb5be;

$exit: #9d11c0;
$success: #009933;
$danger: #cc3333;
$danger-dark: #440000;
$warning: #ffcc33;
$info: #339999;

$gray-250: #f2f3f7;
$gray-400: #cec4da;
$gray-500: #adb5bd;
$gray-700: #495057;
$gray-800: #343a40;
