@import '../../../styles/global';

.gridUiSettings {
    margin-left: 10px;
    margin-right: 10px;

    & th,
    td {
        padding: 9px 16px 9px 16px;
        border: 1px solid #DEE2E6;
        color: #333;
        font-weight: 500;
        line-height: 20px;
        text-align: left;
    }

    & th {
        background-color: $--color-gray-250;
    }
}

.row {
    text-align: center;
}

.noMouseEvent {
    pointer-events: none;
}

.checkBox {
    margin-top: 0px;
    display: block;
}

.defaultSettings {
    width: 350px;
    margin-bottom: 10px;
    margin-left: 10px;

    outline: none;
    background: none;
    cursor: pointer;
}