.searchFormContainer {
    display: flex;
    justify-content: start;
    align-items: center;

    &_searchInput {
        width: 250px;
    }

    &_searchSelect {
        width: 242px;
    }
}
