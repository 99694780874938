@import "../../styles/global";
@import "../../styles/colors";

.hidden {
  display: none !important;
}

.accordion {
  width: 100%;
  margin-top: 5px;
  margin-bottom: 5px;

  &>input {
    position: absolute;
    opacity: 0;
    z-index: -1;
  }

  &>label {
    height: 52px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: Roboto;
    font-style: normal;
    font-weight: 300;
    cursor: pointer;
    position: relative;
    font-size: $--font-size-14;
    line-height: $--line-height-20;

    @include before("xs") {
      font-size: $--font-size-12;
      line-height: $--line-height-18;
    }

    &::after {
      content: "";
      width: 100%;
      border-bottom: 1px solid #dee2e6;
      margin-left: 20px;
      margin-right: 55px;
      margin-bottom: 10px;
    }

    &>div {
      right: 0;
      position: absolute;
      display: block;
      background-color: $--color-gray-250;
      width: 32px;
      height: 32px;
      border-radius: 4px;
    }

    &>div>span {
      transform: translate(-50%, 140%) !important;
    }

    &>p {
      margin-left: 5px;
    }

    &>div::after {
      display: block;
      content: "\276F";
      text-align: center;
      transition: all 0.35s;
      margin-top: 6px;
      transform: rotate(0deg);
      transition: all 0.35s;
    }
  }
}

.accordionClosed {
  @extend .accordion;

  &>label {
    font-weight: 600;
  }
}

.accordionBorderTop{
  @extend .accordion;

  &>label {    
    border-top: 1.3px solid #70a0a16c;
    background-color: #dee2e648;
    border-radius: 10px
  }
}

input:checked {
  +label {
    >div::after {
      transform: rotate(90deg);
      transition: all 0.35s;
    }
  }
}

.documentSticky {
  position: sticky;
  bottom: 60px;
  background-color: white;
  z-index: 2;
}

.boldAccordion {
  width: 100%;
  margin-bottom: 10px;

  &>input {
    position: absolute;
    opacity: 0;
    z-index: -1;
  }

  &>label {
    font-weight: bold;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    font-family: Roboto;
    font-style: normal;
    cursor: pointer;
    position: relative;
    font-size: $--font-size-14;
    line-height: $--line-height-20;

    @include before("xs") {
      font-size: $--font-size-12;
      line-height: $--line-height-18;
    }

    &::after {
      content: "";
      width: 103%;
      // Цвет линии в баяне, его нет в _colors.scss
      border-bottom: 25px solid #f2f3f7;
    }

    &>div {
      position: absolute;
      top: 12px;
      display: block;
      width: 32px;
      height: 32px;
      // margin-right: 30px;
    }

    &>div::after {
      display: block;
      content: "\276F";
      text-align: center;
      transition: all 0.35s;
      margin-top: 6px;
      transform: rotate(270deg);
      transition: all 0.35s;
    }
    &>div>span {
      z-index: 5 !important;
      transform: translate(-49%, 115%) !important;
    }
  }

  input:checked {
    +label {
      >div::after {
        transform: rotate(90deg);
        transition: all 0.35s;
      }
    }
  }
}