@import "../../../styles/global";

.tabMenu {
    @include agBodySmall_normal();
    display: flex;
    flex-direction: column;
    position: absolute;
    background-color: white;
    left: 80px;
    top: 20px;
    padding: 10px 10px 10px 10px;
    border: 1px solid rgba(177, 174, 174, 0.522);
    z-index: 3;
    border-radius: 5px;

    .menuItem {
        cursor: pointer;

        &:hover {
            background-color: rgba(144, 143, 143, 0.516);
        }
    }
}

.root {
    background-color: $--color-gray-250;
    width: max-content;
    height: 36px;
    margin: 1px;
    border-radius: $--border-radius $--border-radius 0px 0px;
    box-sizing: border-box;
    border: 1px solid $--color-gray-300;
    cursor: pointer;
    position: relative;

    &Activated {
        background-color: #fff;
        color: $--color-primary-100;

        path {
            stroke: $--color-primary-100;
        }

    }

    .content {
        margin: 8px 14px;

        @include breakpointMedium() {
            margin: 8px 14px;
        }

        @include breakpointLarge() {
            margin: 8px 16px;
        }

        display: flex;
        flex-direction: row;
        white-space: nowrap;

        @include agBodySmall_normal();

        @include breakpointMedium() {
            @include agBodyMedium_normal();
        }

        @include breakpointLarge() {
            @include agBodyLarge_normal();
        }

        .dotIndicator {
            margin-right: 10px;
        }

        .closeIcon {
            width: 15px;
            height: 15px;
            margin-left: 15px;
            margin: 1px 0px 3px 8px;
    
            @include breakpointMedium() {
                margin: 0.5px 0px 2.5px 8px;
            }
    
            @include breakpointLarge() {
                margin: 2px 0px 2px 8px;
            }
    
            svg {
                width: 13px;
                height: 13px;
    
                path {
                    stroke-width: 2;
                }
            }
        }
    }
}