@import '../../../../styles/global';

.virtualizeDGridRowCell > td {

    @include breakpointMedium {
        height: inherit !important;
    }

    @include breakpointLarge {
        height: inherit !important;
 
    }
}
