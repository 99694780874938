@import "../../../styles/global";

.ContractorsDropdown {

    width: 100%;

    display: inline-flexbox;
    padding-right: 12px;

    input[type=text] {
        margin-left: -9.5%;
        font-family: Roboto;
    }

    input[type=text]::placeholder {
        visibility: hidden;
    }

    input[type=checkbox] {
        margin-right: -18px;
    }

    div {
        font-size: $--font-size-12;
        font-family: Roboto;
    }

    button {
        font-size: $--font-size-12;
        font-family: Roboto;
    }

    svg {
        visibility: hidden;        
    }

    svg[class ~= "dropDownIcon"] {
        visibility: visible !important;        
    }
}

.ContractorsDropdown > div > div {
    width: 102%;
    opacity: 95%;
    padding-top: -5%;
}
