.checkboxIsEnabled {
    margin: 8px 0;
}

.saveSettingsBtn {
    width: 300px;
}

.panelAndSelectorWrapper {
    display: flex;
    align-items: center;

    &_selector {
        flex: 1;
    }
}

.gridWrapper {
    flex: 3;

    &_defaultGrid {
        height: 50vh;
    }
}

.searchForm {
    display: flex;
    align-items: center;
    justify-content: start;
    gap: 20px;

    &_textInput {
        min-width: 500px;
    }

    &_searchButton {
        width: 150px !important;
    }
}