@import '../../../styles/global';

.gridRowCellRed {
  display: flex;
  align-items: center;
  height: 36px;
  padding: 0 16px;
  color: red;
  box-shadow: inset -1px -1px 0px #dee2e6;
  min-width: 177px;

  @include breakpointMedium {
    height: 38px;
  }

  @include breakpointLarge {
    height: 44px;
  }

  > input[type='checkbox'] {
    width: 14px;
    height: 14px;
  }
}

.gridRowCellGreen {
  display: flex;
  align-items: center;
  height: 36px;
  padding: 0 16px;
  color: green;
  box-shadow: inset -1px -1px 0px #dee2e6;
  min-width: 177px;

  @include breakpointMedium {
    height: 38px;
  }

  @include breakpointLarge {
    height: 44px;
  }

  > input[type='checkbox'] {
    width: 14px;
    height: 14px;
  }
}
