@import '../../../../styles/palette';
@import "../../../../styles/global";

.menuItem {
    margin-bottom: 10px;
}

.block {
    //display: flex;

    .sendRequest {
        width: 70%;
        margin: 10px;
    }

    .quantityRequest {
        width: 20%;
    }
}

.reportBlock {
    display: flex;
    justify-content: space-around;
    .frontBlock {
        flex-direction: column;
        display: flex;
        margin-right: 20px;

        .graphBlock {
            display: flex;
        }
    }

    .backendBlock {
        flex-direction: column;
        display: flex;
        margin-right: 20px;

        .graphBlock {
            display: flex;
        }
    }
}

.newField {
    &_alignCenter {
      align-items: center;
    }
  
    &_search {
      grid-column: 1/3;
    }
  
  }

.modal1 {
    //left: 25%;
    width: 100%; //500px;
  }

.quickAccessBar {
    //display: flex;
    padding: 14px 16px;
    width: 100%;
    animation-duration: 1s;
    animation-name: slidersOpen;
    justify-content: space-between;

    .quickAccessBarBase {
        display: flex;
    }

    //.quickAccessBarHiddenBox {}
}

.quickAccessBarSeparator {
    height: 36px;
    //display: flex;
    justify-content: center;
    align-items: center;

    span {
        border: 1px solid $gray-400;
        border-radius: 4px;
        background-color: $gray-400;
        height: 24px;
        margin: 0px 10px;
    }
}

.solid {
    margin: 5px 0;
    height: 1px;
    background-color: $--color-gray-600;
    width: 100%;
    display: block;

    &Sub {
        @extend .solid;
        background-color: $--color-gray-500;
        margin: 3px 24px 13px;
        width: auto;
        max-width: 176px;
        display: none;

        //@include before("lg") {
            margin: 4px 24px 11px;
        //}

        @include before("md") {
            margin: 1px 24px 13px;
        }
    }
}

.field_Recalculate {
    margin-top: 10px;
    grid-column: 6 / 7;
    height: 20px;
}