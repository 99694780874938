.wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
}

.layout {
    height: 80%;
}

.button {
    width: 200px;
}

.filed {
  &_gridWrapper {
    align-items: end;
  }

  &_File {
    grid-column: 1/3;
  }

  &_ClearFiles {
    height: 35px;
  }

  &_actionWrapper {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 8px;
  }

  &_checkbox {
    min-width: 120px;
  }
}

.list_container {
    display: flex;
    flex-direction: column;

    margin-left: 20px;
    margin-bottom: 20px;
  }

  .button_container {
    display: flex;
    flex-direction: column;

  }

  .tooltip {
    visibility: hidden;  /* Or display: none, depending on how you want it to behave */
  }
  
  .tooltip-on-hover:hover + .tooltip {    /* Uses the adjacent sibling selector */
    visibility: visible;  /* Or display: block */
  }

  .processModal {
    justify-content: center;
  }