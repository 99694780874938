@import "../../../../../styles/mixins";
@import '../../../../../styles/global';

.sellingPriceLabel {
    color: #006699;
}
.gridContainer {
    grid-gap: 10px;
}

.field {
    &_Goods {
        grid-column: 1/25;
    }
    &_ScalingRatio {
        grid-column: 25/29;
    }
    &_Kiz {
        margin-top: 30px;
        font-weight: 500;
        font-size: 10px;
        line-height: 10px;

        grid-column: 29/29;

        width: 36px;
        height: 36px;
        background: #FFFFFF;

        border: 1px solid $--color-status-negative;
        box-sizing: border-box;
        border-radius: $--border-radius;
        color: $--color-status-negative;

        flex: none;
        order: 1;
        flex-grow: 0;

        text-align: center;
        padding-top: 12px;
        
        &_Disabled {
            border: 1px solid $--color-gray-400;
            color: #666;
            background-color: $--color-gray-200;
        }
    }
    &_Jv {
        font-weight: 500;
        font-size: 10px;
        line-height: 10px;

        grid-column: 30/30;
        margin-top: 30px;

        width: 36px;
        height: 36px;
        color: #339999;
        background: #FFFFFF;

        border: 1px solid #339999;
        box-sizing: border-box;
        border-radius: $--border-radius;

        flex: none;
        order: 2;
        flex-grow: 0;

        text-align: center;
        padding-top: 12px;

        &_Disabled {
            border: 1px solid $--color-gray-400;
            color: #666;
            background-color: $--color-gray-200;
        }
    }
    &_CountDocs {
        grid-row: 1;
    }
    &_CountFact {
        grid-row: 1;
    }
    &_Boi {
        grid-row: 1;
    }
    &_Defect {
        grid-row: 1;
    }
    &_Shortage {
        grid-row: 1;
    }
    &_Other {
        grid-row: 1;
    }
    &_MinCount {
        grid-row: 1;
    }
    &_CheckBox{
        text-align: center;
        height: auto;
        display: flex;
        align-items: center;
        grid-row: 1;
        padding-top: 18px;
    }
    &_ManufacturerMarginalPrice {
        grid-column: 1/2;
    }
    &_Store {
        grid-column: 3/7;
    }
    &_WholesaleSurcharge {
        grid-column: 2/3;
    }
    &_VatRateWholesale {
        grid-column: 3/4;
    }
    &_ActualMarkUpPercent {
        grid-column: 4/5;
    }
    &_RetailVat {
        grid-column: 5/6;
    }
    &_RetailVatRate {
        grid-column: 6/7;
    }
    &_SuppliersPrice {
        grid-column: 1/2;
    }
    &_SupplierVatPerUnit {
        grid-column: 2/3;
    }
    &_SupplierPriceWithVat {
        grid-column: 3/4;
    }
    &_AmountWithVat {
        grid-column: 4/5;
    }
    &_InterestMargin {
        grid-column: 5/6;
    }
    &_ActualMarkUp {
        grid-column: 6/7;
        margin-top: 28px;
    }
    &_SellingPrice {
        grid-column: 7/8;
    }
    &_SupplierAmount {
        grid-column: 1/2;
    }
    &_SupplierVatAmount {
        grid-column: 2/3;
    }
    &_AmountOfThePaymentWithVat {
        grid-column: 3/4;
    }
    &_RetailAmountWithoutVat {
        grid-column: 4/5;
    }
    &_VatAmount {
        grid-column: 5/6;
    }
    &_RetailAmountWithVat {
        grid-column: 7/8;
    }
    
}
.link {
    color: #9a9a9a;
    text-decoration-line: underline;
    font-family: Roboto;
    font-style: normal;
    font-weight: 600;

    font-size: $--font-size-14;
    line-height: $--line-height-20;  

    @include before("xs") {
        font-size: $--font-size-12;
        line-height: $--line-height-18;
    }
}
.link_sertificate {
    @extend .link;
    cursor: pointer;
    color: #339999;
}
.toolsPanel {
    margin-bottom: 10px;
}

.boldLabel {
    label {
        font-weight: bold;
    }
}