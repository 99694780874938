.modalWindow {
    width: 1040px;
    
    height: auto !important;

    footer > div {
        box-shadow: none !important;
    }
}
.toolsPanel {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
}
.contentBlock {
   
    min-height: 300px;
}
.field {
    &_product {
        grid-column: 1 / 3;
    }
    &_seriesNumber {
        grid-column: 3 / 4;
    }
    &_shelfLife {
        grid-column: 4 / 5;
        max-width: 174px;
    }
    &_releaseDate {
        grid-column: 5 / 6;
        max-width: 174px;
    }
}
