@import '../../../styles/mixins';
@import '../../../styles/global';

@mixin modalWindow {
  display: flex;
  position: fixed;
  top: 50%;
  left: 50%;
  width: auto;
  height: auto;
  max-width: 100vh;
  max-height: 100vh;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  transform: translate(-50%, -50%);
  background: #fff;
  box-shadow: 0px 0px 20px rgba(73, 80, 87, 0.3);
  border-radius: $--border-radius;
}

@mixin fullScreenModalWindow {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  max-width: 100%;
  max-height: 100%;
  height: 100%;
  background: #fff;
  box-shadow: 0px 0px 20px rgba(73, 80, 87, 0.3);
  border-radius: $--border-radius;
}

.boxModal {
  width: 80%;
}

.footer {
  display: flex;
  width: 100%;
  z-index: 0;
  justify-content: space-between;
  padding: 10px;
  box-shadow: 0 15px 30px 10px rgba(0, 0, 0, 0.2);
}

.footerButton {
  width: 130px;
}

.modalWindowSearch {
  min-width: 1000px !important;
  height: auto !important;
}

.flexModalWindow {
  width: auto !important;
  height: 500px;

  footer > div {
    box-shadow: none !important;
  }
}

.flexHeightModalWindow {
  width: 80%;
  height: auto !important;

  footer > div {
    box-shadow: none !important;
  }
}

.modalWindowBase {
  width: 1100px;
  max-height: 600px;

  footer > div {
    box-shadow: none !important;
  }
}

.modalWindowMovement {
  max-width: 750px;
  min-width: 500px !important;
  min-height: 300px;
  height: auto !important;

  footer > div {
    box-shadow: none !important;
  }
}

.modalWindowSmallBase {
  max-width: 750px;
  min-width: 500px !important;
  height: auto !important;

  footer > div {
    box-shadow: none !important;
  }
}

.modalWindowConfirm {
  max-width: 900px;
  min-width: 100px !important;
  min-height: 191px;
  height: auto !important;
  max-height: 70%;
}

.footerConfirm {
  padding-top: 0px;
  padding-bottom: 20px;
  padding-left: 30px;
  padding-right: 30px;
  box-shadow: 0px 0px 15px rgba(73, 80, 87, 0.2);
  width: 100%;
  display: flex;
  flex-direction: row;
  align-self: flex-end;

  & > button {
    width: auto;
    min-height: 38px;
    margin-right: 15px;
    padding: 0 25px;
  }

  & > button:last-child {
    margin-right: 0;
  }
}

.customFooterContainer {
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 30px;
  padding-right: 30px;
  box-shadow: 0px 0px 15px rgba(73, 80, 87, 0.2);
  width: 100%;
  display: flex;
  justify-content: flex-start;

  & > button {
    max-width: 250px;
    width: 10%;
    min-height: 30px;
    margin-right: 20px;
  }
}

.modalEditValues {
  @include modalWindow();
  min-width: 800px;
  resize: both;

  &_FullScreen {
    width: 100%;
    height: 100%;
  }

  &_TreeView {
    width: 400px;
  }

  & > header {
    width: 100%;
    display: flex;
    background-color: $--color-gray-250;
    border-radius: 4px 4px 0 0;

    & > .headerContainer {
      display: flex;
      flex-direction: row;
      align-self: flex-end;
      justify-content: flex-end;
      width: 100%;
      margin-left: 30px;
      margin-right: 30px;
      margin-top: 16px;
      margin-bottom: 16px;

      & > .title {
        font-size: $--font-size-16;
        line-height: $--line-height-24;
        font-family: Roboto;
        font-style: normal;
        font-weight: $--font-weight-medium;
        margin-right: auto;
      }

      & > .closeButton {
        background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M18 6L6 18' stroke='%23495057' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M6 6L18 18' stroke='%23495057' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
        background-position: 50% 50%;
        width: 24px;
        height: 24px;
        align-self: center;
        background-color: transparent;
        cursor: pointer;
      }

      & > .fullScreen {
        display: flex;

        & > .closeButton {
          background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M18 6L6 18' stroke='%23495057' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M6 6L18 18' stroke='%23495057' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
          background-position: 50% 50%;
          margin-left: 10px;
          width: 24px;
          height: 24px;
          align-self: center;
          background-color: transparent;
          cursor: pointer;
        }
      }
    }
  }

  & > main {
    @include scroll-bar();
    width: 100%;
    height: auto 220px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    overflow: scroll;

    & > .mainContainer {
      display: flex;
      flex-direction: column;
      max-width: calc(100% - 60px);
      margin-left: 30px;
      margin-right: 30px;
      margin-top: 10px;
    }
  }

  & > footer {
    display: flex;
    width: 100%;
    margin-top: auto;

    & > .footerContainer {
      padding-top: 20px;
      padding-bottom: 20px;
      padding-left: 30px;
      padding-right: 30px;
      box-shadow: 0px 0px 15px rgba(73, 80, 87, 0.2);
      width: 100%;
      display: flex;
      flex-direction: row;
      align-self: flex-end;

      & > button {
        width: auto;
        min-height: 38px;
        margin-right: 15px;
        padding: 0 25px;
      }

      & > button:last-child {
        margin-right: 0;
      }
    }
  }
}

.fullScreenModal {
  @include fullScreenModalWindow();
  min-width: 800px;

  &_FullScreen {
    width: 100%;
    height: 100%;
  }

  &_TreeView {
    width: 400px;
  }

  & > header {
    width: 100%;
    display: flex;
    background-color: $--color-gray-250;
    border-radius: 4px 4px 0 0;

    & > .headerContainer {
      display: flex;
      flex-direction: row;
      align-self: flex-end;
      justify-content: flex-end;
      width: 100%;
      margin-left: 30px;
      margin-right: 30px;
      margin-top: 16px;
      margin-bottom: 16px;

      & > .title {
        font-size: $--font-size-16;
        line-height: $--line-height-24;
        font-family: Roboto;
        font-style: normal;
        font-weight: $--font-weight-medium;
        margin-right: auto;
      }

      & > .closeButton {
        background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M18 6L6 18' stroke='%23495057' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M6 6L18 18' stroke='%23495057' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
        background-position: 50% 50%;
        width: 24px;
        height: 24px;
        align-self: center;
        background-color: transparent;
        cursor: pointer;
      }

      & > .fullScreen {
        display: flex;

        & > .closeButton {
          background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M18 6L6 18' stroke='%23495057' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M6 6L18 18' stroke='%23495057' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
          background-position: 50% 50%;
          margin-left: 10px;
          width: 24px;
          height: 24px;
          align-self: center;
          background-color: transparent;
          cursor: pointer;
        }
      }
    }
  }

  & > main {
    @include scroll-bar();
    width: 100%;
    height: auto 220px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    overflow: scroll;

    & > .mainContainer {
      display: flex;
      flex-direction: column;
      max-width: calc(100% - 60px);
      margin-left: 30px;
      margin-right: 30px;
      margin-top: 20px;
      margin-bottom: 20px;
    }
  }

  & > footer {
    display: flex;
    width: 100%;
    margin-top: auto;

    & > .footerContainer {
      padding-top: 20px;
      padding-bottom: 20px;
      padding-left: 30px;
      padding-right: 30px;
      box-shadow: 0px 0px 15px rgba(73, 80, 87, 0.2);
      width: 100%;
      display: flex;
      flex-direction: row;
      align-self: flex-end;

      & > button {
        width: auto;
        min-height: 38px;
        margin-right: 15px;
        padding: 0 25px;
      }

      & > button:last-child {
        margin-right: 0;
      }
    }
  }
}

.modalOverlay {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0px;
  top: 0px;
  z-index: 11;
  background: rgba(0, 0, 0, 0.5);
}
