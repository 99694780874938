@import '../../../styles/global';

.gridTable {
    display: flex;
    flex-direction: column;
    border-radius: $--border-radius;
    position: relative;

    [tabindex] {
        outline: none !important;
    }

    margin-bottom: 10px;
}

.gridColumnsHeader {
    display: flex;
    flex-direction: row;
    position: sticky;
    top: -1px;
    height: 44px;
}

.gridColumnHeaderName {
    font-weight: bold;
    cursor: default;
}

.gridColumn {
    display: flex;
    flex-direction: row;
    background-color: $--color-gray-250;
    // border: 1px solid $--color-gray-300;
}

.gridColumnCell {
    resize: horizontal;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 9px 16px 9px 16px;
    justify-content: center;
    color: $--color-text-heading;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    box-shadow: inset -1px 0px 0px #dee2e6;
    min-width: 100px;
    @include agBodySmall-medium;

    @include breakpointMedium {
        @include agBodyMedium-medium;
    }

    @include breakpointLarge {
        @include agBodyLarge-medium;
    }

    &[data-filter-selected="true"] {
        background-color: $--color-grid-danger;
    }

    span {
        max-width: 100%;
        text-overflow: ellipsis;
        overflow: hidden;
    }
}

.additionalGridColumnCell {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 9px 16px 9px 16px;
    justify-content: center;
    color: $--color-text-heading;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    box-shadow: inset -1px 0px 0px #dee2e6;
    min-width: 177px;
    @include agBodySmall-medium;

    @include breakpointMedium {
        @include agBodyMedium-medium;
    }

    @include breakpointLarge {
        @include agBodyLarge-medium;
    }

    &[data-filter-selected="true"] {
        background-color: $--color-grid-danger;
    }

    span {
        max-width: 100%;
        text-overflow: ellipsis;
        overflow: hidden;
    }
}

.gridColumnCell::-webkit-resizer {
    background-color: transparent;
}

.gridColumnCellButtons {
    height: 20px;
    margin-left: 5px;
    display: flex;
    cursor: pointer;

    .gridFilterButton {
        width: 50%;

        svg {
            margin: 4px 4px;
            width: 12px;
            height: 12px;
            fill: $--color-grid-font-danger;
            stroke: $--color-grid-font-danger;
        }
    }

    &[data-sorted="true"] .gridSortButton {
        width: 50%;

        & svg {
            path {
                stroke: red;
            }
        }
    }

    .searchDropDivButton {
        margin-top: -8px;
        margin-left: -5px;
    }
}

.sortIcon {
    width: 16px;
    height: 19px;

    path {
        stroke-width: 2;
    }

    &_Selected {
        stroke: #006699;

        path {
            stroke-width: 2.5;
        }
    }
}

.gridRowsGroup {
    display: flex;
    flex-direction: column;
    box-shadow: inset 1px 0px 0px #dee2e6;


}



.gridRow {
    display: flex;
    // box-shadow: inset 1px 1px 0px #DEE2E6;
    width: max-content;
}

.gridRowSelected {
    display: inline-flex;
    background-color: $--color-grid-select;
    width: max-content;
}

.gridRowDeleted {
    display: inline-flex;
    background-color: $--color-grid-danger;
    color: $--color-grid-font-danger;

    &.gridRowSelected {
        display: inline-flex;
        background-color: $--color-grid-select;
        color: $--color-grid-font-danger;
    }
}

.gridRowLocked {
    display: inline-flex;
    background-color: $--color-grid-gray;
    color: $--color-grid-font-gray;

    &.gridRowSelected {
        display: inline-flex;
        background-color: $--color-grid-select;
        color: $--color-grid-font-gray;
    }
}

.gridRowCell {
    display: flex;
    align-items: center;
    //background: #FFFFFF;
    height: 36px;
    padding: 0 16px;
    // box-shadow: inset -1px -1px 0px #DEE2E6;
    box-shadow: inset -1px -1px 0px #dee2e6;
    min-width: 100px;
    height: 44px;
    
    >input[type="checkbox"] {
        width: 14px;
        height: 14px;
        accent-color: $--color-primary-100;
    }
}

.gridRowCellRed {
    display: flex;
    align-items: center;
    //background: #FFFFFF;
    height: 36px;
    padding: 0 16px;
    color: red;
    // box-shadow: inset -1px -1px 0px #DEE2E6;
    box-shadow: inset -1px -1px 0px #dee2e6;
    min-width: 100px;
    height: 44px;

    >input[type="checkbox"] {
        width: 14px;
        height: 14px;
    }
}

.gridRowCellGreen {
    display: flex;
    align-items: center;
    //background: #FFFFFF;
    height: 36px;
    padding: 0 16px;
    color: green;
    // box-shadow: inset -1px -1px 0px #DEE2E6;
    box-shadow: inset -1px -1px 0px #dee2e6;
    min-width: 100px;
    height: 44px;

    >input[type="checkbox"] {
        width: 14px;
        height: 14px;
    }
}

.gridRowCellText {
    //-webkit-user-select: all; // Выделение слова в один клик в ячейке
    //user-select: all;
    white-space: nowrap;
    overflow: hidden;
    // text-overflow: ellipsis;

    @include agBodySmall_normal;

    @include breakpointMedium {
        @include agBodyMedium_normal;
    }

    @include breakpointLarge {
        @include agBodyLarge_normal;
    }
}

.noDataInfo {
    display: flex;
    justify-content: center;
}

.gridWrapperScrollTop {
    height: 20px;
    overflow-x: scroll;
    overflow-y: hidden;

    &::-webkit-scrollbar {
        width: 8px;
        height: 9px;
    }

    &::-webkit-scrollbar-track {
        -webkit-box-shadow: none;
        background-color: transparent;
        border-radius: 30px;
    }

    &::-webkit-scrollbar-thumb {
        border-radius: 30px;
        background: #adb5bd;
    }
}

.div1 {
    height: 20px;
}

.gridWrapper {
    width: 100%;

    &__tables {
        width: 100%;
        margin: 0;
        max-height: calc(100vh - 293px);
        height: 100%;
        overflow-y: scroll;
        @include double-scroll-bar();

        &__disabledScroll {
            @include double-scroll-bar-hidden();
            overflow-y: hidden;
            color: #666;
            background-color: $--color-gray-100;
        }
    }

    .spin {
        position: fixed;
        top: 40%;
        left: 50%;

        @include breakpointXs {
            left: 60%;
        }

        @include breakpointLarge {
            left: 50%;
        }

        @include breakpointXl {
            left: 40%;
        }
    }
}

.noMouseEvent {
    pointer-events: none;
}

.tooltipText {
    display: table;
    width: 100%;
    table-layout: fixed;
    -webkit-user-select: all;
    user-select: all;
}

@keyframes visible {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

.quantityPositions {
    color: #666;
}