@import '../../styles/_global.scss';

.formWrapper {
    box-shadow: 0px 0px 20px rgba(73, 80, 87, 0.1);
    border-radius: $--border-radius;
    height: auto;
    min-height: 400px;
    padding: 45px 35px;
    background: #ffffff;
    margin: auto;
    width: 100%;
    max-width: 400px;
    position: relative;
    z-index: 1;
}
.logo {
    width: 121px;
    height: 50px;
    display: block;
}
.enter {
    margin-top: 40px;
    color: $--color-text-heading;
}

.headerMessage {
    margin-top: 20px;
    color: $--color-text-body-100;
    // white-space: pre;
}
.baseWrapper {
    display: flex;
    flex-direction: column;
}
.baseLabel {
    color: $--color-text-body-75;
    font-weight: $--font-weight-regular;
    font-size: $--font-size-13;
    line-height: 19px;
}
.emailWrapper {
    @extend .baseWrapper;
    margin-top: 10px;
}
.emailLabel {
    @extend .baseLabel;
    margin-top: 10px;
}
.baseInput {
    font-size: $--font-size-16;
    font-weight: $--font-weight-regular;
    line-height: $--line-height-24;
    padding: 7px 12px;
    height: 38px;
    outline: none;
    border-radius: $--border-radius;
    color: $--color-text-body-100;

    &:placeholder {
        color: $--color-text-body-100;
    }
}
.baseInputBorder {
    border: 1px solid #ced4da;

    &:focus {
        border: 1px solid #99ccff;
        box-shadow: 0px 0px 0px 3.2px rgba(0, 123, 255, 0.25);
    }
}
.emailInput {
    @extend .baseInput, .baseInputBorder;
    margin-top: 8px;
}

.changeBtn {
    margin-top: 30px;
}

.cancelBtn{
    margin-top: 20px;
}


.loginForm__content {
    max-width: 492px;
    width: 100%;
    position: relative;
}

.loginFormBg__before {

    display: block;
    width: 380px;
    height: 380px;
    position: absolute;
    z-index: 0;

    &::after {
        content: '';
        width: 310px;
        height: 233px;
        background-color: rgba(170, 197, 229, 0.1);
        display: block;
        position: absolute;
        top: -46px;
        left: 16px;
        border-radius: 6px;
    }

    &::before {
        content: '';
        width: 380px;
        height: 380px;
        display: block;
        border: 1px dashed rgba(0, 102, 153, 0.2);
        border-right: none;
        border-bottom: none;
        position: absolute;
        top: -30px;
        left: 0;
        border-radius: 6px 0px 6px 0px;
    }
}

.loginFormBg__after {
    display: block;
    width: 380px;
    height: 380px;
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: 0;

    &::after {
        content: '';
        width: 246px;
        height: 186px;
        background-color: rgba(170, 197, 229, 0.1);
        display: block;
        position: absolute;
        bottom: -26px;
        right: 20px;
        border-radius: 6px;
    }

    &::before {
        content: '';
        width: 306px;
        height: 304px;
        display: block;
        border: 1px dashed rgba(0, 102, 153, 0.2);
        border-left: none;
        border-top: none;
        position: absolute;
        bottom: -47px;
        left: 72px;
        border-radius: 0 0px 6px 0;
    }
}