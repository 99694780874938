$corporate-FFFFFF: #FFFFFF;
$corporate-34495E: #34495E;
$corporate-337AB7: #337AB7;
$corporate-6699CC: #6699CC;
$corporate-B3BAC9: #B3BAC9;
$corporate-D7DDEB: #D7DDEB;
$corporate-039BB3: #039BB3;
$corporate-006699: #006699;
$corporate-336699: #336699;

/*gray color*/
$--color-gray-100: rgb(248, 249, 250);
$--color-gray-200: rgb(233, 236, 239);
$--color-gray-250: rgb(242, 243, 247);
$--color-gray-300: rgb(222, 226, 230);
$--color-gray-400: rgb(206, 212, 218);
$--color-gray-500: rgb(173, 181, 189);
$--color-gray-600: rgb(108, 117, 125);
$--color-gray-700: rgb(73, 80, 87);
$--color-gray-800: rgb(52, 58, 64);
$--color-gray-900: rgb(33, 37, 41);



