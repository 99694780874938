@import "../../styles/_global.scss";

.radioButton {
  
  &:not(:last-child) {
    margin-right: 20px;
  }

  input {
    position: absolute;
    z-index: -1;
    opacity: 0;
    margin: 10px 0 0 7px;
  }

  &__text {
    position: relative;
    padding: 0 0 0 22px;
    cursor: pointer;
    color: $--color-text-heading;

    font-size: $--font-size-14;
    line-height: $--line-height-20;  

    @include before("xs") {
        font-size: $--font-size-12;
        line-height: $--line-height-18;
    }

    &:before {
      content: '';
      position: absolute;
      top: 50%;
      left: 0;
      width: 16px;
      height: 16px;
      border: 1.5px solid $--color-gray-600;
      border-radius: 50%;
      background: #FFF;
      transform: translateY(-50%);
    }

    &:after {
      content: '';
      position: absolute;
      top: 50%;
      left: 4px;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background: $--color-primary-100;
      opacity: 0;
      transition: .2s;
      transform: translateY(-50%);
    }
  }
}

.radioButton input:checked + .radioButton__text:after {
	opacity: 1;
}

.radioButton input:focus + .radioButton__text::before {
  outline: auto;
}

.radioGroup {
  display: flex;
  align-items: center;
}

.radioGroup_vertical {
  display: flex;
  flex-direction: column;
  align-items: start;
  align-content: flex-start;
  margin-left: 10px;
  margin-top: 5px;

  .radioButton {
    margin-bottom: 10px;
  }
}
