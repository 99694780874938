@import "../../styles/global";
@import "../../styles/colors";


.boldAccordion {
    width: 100%;
    margin-bottom: 10px;

    &>input {
        position: absolute;
        opacity: 0;
        z-index: -1;
    }

    &>label {
        //margin-left: 30px;
        font-weight: bold;
        height: 15px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        font-family: Roboto;
        font-style: normal;
        cursor: pointer;
        position: relative;
        font-size: $--font-size-14;
        line-height: $--line-height-20;

        @include before("xs") {
            font-size: $--font-size-12;
            line-height: $--line-height-18;
        }

        &::after {
            content: "";
            width: 100%;
            // Цвет линии в баяне, его нет в _colors.scss
            border-bottom: 25px solid #f2f3f7;
        }

        &>span {
            position: absolute;
            top: -5px;
            display: block;
            width: 32px;
            height: 32px;
            // margin-right: 30px;
        }

        &>span::after {
            display: block;
            content: "\276F";
            text-align: center;
            transition: all 0.35s;
            margin-top: 6px;
            transform: rotate(270deg);
            transition: all 0.35s;


        }


    }


}

.hidden {
    display: none !important;
}