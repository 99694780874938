@import "../../../../styles/global";
@import "../../../../styles/colors";

.convolutionButton {
    margin-top: 6px;
    height: 32px;

    z-index: 100;
    display: flex;
    align-items: center;
    background-color: #fff;

    svg > path {
        stroke: #666666;
        fill: #666666;
    }

    &:hover,
    &:focus {
        background-color: transparentize($--color-primary-100, 0.9);
        stroke: $--color-primary-100;
        transition: $--transition-settings;

        .MainTooltip {
            visibility: visible;
        }

        div {
            color: #006699;
        }

        svg > path {
            stroke: #006699;
            fill: #006699;
        }
    }
}

.convolutionButton[disabled]:active, .convolutionButton[disabled], .convolutionButton[disabled]:hover {
    div {
        color: #abb5be;  
    }

    svg > path {
        stroke: #abb5be;
        fill: #abb5be;
    }
}

.field {
    &_Name {
        grid-column: 1/3;
    }

    &_Email {
        grid-column: 1/3;
    }

    &_UrAddress {
        grid-column: 1/4;
    }

    &_BankAddress {
        grid-column: 1/3;
    }

    &_LegalPers {
        grid-column: 1/4;
    }

    &_LegalPersShort {
        grid-row: 2;
    }

    &_DirectorFio {
        grid-column: 1/3;
    }

    &_BuhFio {
        grid-column: 1/3;
    }

    &_DirectorDoc {
        grid-column: 3/5;
    }

    &_BuhDoc {
        grid-column: 3/5;
    }
}