@import '../../../styles/palette';
@import '../../../styles/global';

.field {
    &_Filter {
        grid-column: 1/4;
    }

    &_Filter {
        grid-column: 1/4;
    }
}

.wrapper {
    display: grid;
    grid-column-gap: 0.2em;
    grid-row-gap: 0.2em;
    width: 100%;
}

.wrapper>div {
    padding: 0em;
    margin: 0em;
}

.wrapper>div.light {
    padding: 0em;
    margin: 0em;
    background-color: yellow;
}

.wrapper>div.calendar {
    padding: 0em;
    margin: 0em;
    background-color: magenta;
    display: grid;
    grid-template-columns: 1fr 1fr;

    .calendarLabel {
        background-color: mediumseagreen;
        justify-self: end;
    }
}

.box1 {
    background-color: green;
}

.dict {
    background-color: moccasin;
}

.checkBox {
    padding: 0em;
    margin: 0em;
    background-color: gray;
}

.singlePlugin {
    grid-column: "span 10"
}

.buttonContainer {
    display: flex;
    justify-content: space-between;
}

.footerContainer {
    position: -webkit-sticky;
    position: sticky;
    bottom: 0;
    margin-top: auto;
    display: flex;
    justify-content: space-between;
    background: white;

    &::after {
        content: '';
        position: absolute;
        display: block;
        left: -16px;
        right: -16px;
        background-color: #fff;
        box-shadow: 0px 0px 15px rgba(73, 80, 87, 0.2);
        border-radius: 0px 0px 4px 4px;
        bottom: -8px;
        z-index: -1;
    }
}

.button {
    margin: 5px;
    padding: 0 24px;
}

.secondGroup {
    display: flex;
    white-space: nowrap;
}

.frame {
    //https://css-tricks.com/prevent-white-flash-iframe/
    visibility: 'hidden';
    height: 100%;
    width: 100%;
}