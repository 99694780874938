@import "../../../../styles/global";

.input {
  display: flex;
  align-items: center;
}

.wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .field {
    &_CompactMode {
      grid-column: 35 / 35;
      margin-top: 5px;
    }

    &_Number {
      grid-column: 1 / 21;
    }

    &_Date {
      grid-column: 21 / 25;
    }

    &_BillNumber {
      grid-column: 1 / 2;
    }

    &_BillDate {
      grid-column: 2 / 3;
    }

    &_TargetProgram {
      grid-column: 3 / 5;
    }

    &_PriceModel {
      grid-column: 1 / 6;
      grid-row: 1 / 5;
    }

    &_Recalculate {
      margin-top: 6px;
      grid-column: 6 / 6;
      grid-row: 1 / 2;
    }

    &_SelectionGoods {
      grid-column: 1 / 7;
    }

    &_TypeCode {
      margin-top: 30px;
    }

    &_Empty {
      margin: 0 0 0 15px;
      min-width: 165px;

      label {
        display: none;
      }

      div {
        margin: 0 !important;
      }
    }

    &_SelectionGoods {
      width: 100%;
      margin-left: 10px;
      margin-bottom: 0 !important;
      margin-right: 15px;
    }

    &_flexbox {
      align-items: center;
    }
  }
}

.field_SelectBlock {
  margin-top: 7px;
}

.kizBarcode {
  width: 28px;
  height: 28px;
  border: 1px solid $--color-gray-400;
  display: flex;
  align-items: center;
  justify-content: center;
  background: none;
  border-radius: $--border-radius;
}

.tools {
  display: flex;
}

.compactField {
  &Fields {
    grid-column: 1 / 34;
  }

  &CompactRecalculate {
    margin-top: 30px;
  }

  &CompactMode {
    grid-column: 35 / 35;
    margin-top: 5px;
  }
}

.SettingsButton {
  display: flex;
  width: 36px;
  height: 36px;
  background-color: $--color-gray-250;
  border: 1px solid $--color-gray-500;
  border-radius: $--border-radius;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 10px;

  button {
    width: 100%;
    height: 100%;
  }

  svg {
    padding: 0;
    width: 22px;
    height: 22px;
  }
}

.supplierLabel {
  padding-right: 10px;
}

.pharmacyLabel {
  padding-right: 40px;
}

.storeLabel {
  width: auto;
  padding-right: 18px;
}

.invoiceNumberLabel {
  padding-right: 15px;
}

.glwrapper {
  height: 100%;
}

.mainBox {
  animation-duration: 0.5s;
  animation-name: visible;
  animation-fill-mode: forwards;
}

@keyframes visible {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.gridSelInputCustom {
  &#{&}#{&} {
    align-items: baseline;

    &>div {
      margin:  0px 8px 0px 8px;
    }
  }
}

.baseline {
  &#{&}#{&} {
    align-items: baseline;
  }
}
