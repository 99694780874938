.arrowClass {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    margin-top: -17px !important;
    width: 30px !important;
    height: 36px !important;
}

.arrowHoverClass {
    background-color: rgba(0, 102, 153, 0.1) !important;
    border-radius: 4px !important;
}