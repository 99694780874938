.footerContainer {
    position: -webkit-sticky;
    position: sticky;
    bottom: 0;
    margin-top: auto;
    display: flex;
    justify-content: space-between;
    background: white;
  
    &::after {
      content: '';
      position: absolute;
      display: block;
      left: -16px;
      right: -16px;
      background-color: #fff;
      box-shadow: 0px 0px 15px rgba(73, 80, 87, 0.2);
      border-radius: 0px 0px 4px 4px;
      bottom: -8px;
      z-index: -1;
      height: 68px;
    }
  }
  
  .button {
    margin: 5px;
    padding: 0 25px;
  }
  .secondGroup {
    display: flex;
  }
  