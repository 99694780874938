@import '../../../styles/palette';

.common {
    box-sizing: border-box;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    flex-shrink: 0;
}

.draft {
    width: 21px;
    height: 21px;
    stroke: $gray-700;
}

.proc {
    width: 21px;
    height: 21px;
    stroke: $success;
}

.exit {
    width: 21px;
    height: 21px;
    stroke: $exit;
    fill: $exit;
}

.del {
    width: 21px;
    height: 21px;
    stroke: $danger;
}

.wrapper {
    display: flex;
    justify-content: flex-start;
}

.label {
    margin-right: 5px;
}

.kizSave {
    width: 21px;
    height: 21px;

    stroke: $warning;
    fill: $warning;
}

.kizProc {
    width: 21px;
    height: 21px;
    stroke: $success;
    fill: $success;
}

.kizExit {
    width: 21px;
    height: 21px;
    stroke: $danger;
    fill: $danger;
}

.kizWrapper {
    display: flex;
    justify-content: flex-start;

    :hover {
        border: 2px solid gray;
    }
}

.editKiz {
    box-sizing: border-box;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    flex-shrink: 0;
}