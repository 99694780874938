.wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.button {
    width: 200px;
}
.button_container {
    display: flex;
    justify-content: space-between;
}
.button_container__children {
    @extend .button_container;
    justify-content: center;
    margin-top: 12px; 
}
.filed {
    &_File {
        grid-column: 1/20;
    }
    &_ClearFiles {
        grid-column: 20/20;
        margin-top: 30px;
        height: 35px;
    }
}
