.layout {
    width: 100%;
    height: 100%;
    padding: 10px;
}

.fileInput {
    grid-column: 1/20;
}



.clearButton {
    grid-column: 20/20;
    margin-top: 30px;
}

.dropZoneWrapper {
    display: flex;
    justify-content: center;
    height: 250px;
    width: 100%;
}

.xlLayout {
    display: flex;
    flex-direction: row;
    height: 100%;
}

.xlFileBlock {
    display: flex;
    flex-direction: column;
    width: 33%;
    height: 100%;
    margin-left: 5px;

    .fileInput {
        display: flex;
        width: 100%;

        .input {
            width: 100%;
        }
    }

    .button {
        margin-top: 30px;
    }

    .dropZoneWrapperXl {
        display: flex;
        justify-content: center;
        height: 250px;
        width: 100%;
    }
}

.separator {
    width: 100%;

    &>label {
        //margin-left: 30px;
        display: flex;
        width: 100%;
        align-items: center;
        font-family: Roboto;
        font-style: normal;
        font-weight: 500;
        position: relative;

        &::after {
            content: '';
            width: 100%;
            border-bottom: 1px solid #e87676;
            margin-bottom: 10px;
        }

    }
}