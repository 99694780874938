@import '../../../styles/global';

.root {
    position: relative;
    z-index: 3;

    &Visible {
        display: block;
        height: 100%;
    }

    &Hidden {
        display: none;
    }

    .wrapper {
        height: 100%;
        max-height: calc(100% - 10px);

        &>div {
            height: 100%;
            position: relative;
            display: flex;
            flex-direction: column;
        }
    }
}