@import "../styles/global";

.wrapper {
    position: fixed;
    top: 0;
    right: 0;
    z-index: 11;
    width: 100vw;
    height: 100vh;
    background-color: $--color-gray-200;
    display: flex;
    justify-content: center;
}

.wrapperShowMainMenu {
    position: fixed;
    top: 0;
    right: 0;
    z-index: 11;
    width: calc(100vw - 250px);
    height: 100vh;
    background-color: $--color-gray-200;
    display: flex;
    justify-content: center;
}

.transparency {
    opacity: 0.5;
}

.progressActionSpinner {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    &__loaderText {
        color: #006699;
        font-size: $--font-size-18;
        margin-top: 10px;
    }
}