.menuItem {
    margin-bottom: 10px;
}

.block {
    display: flex;

    .sendRequest {
        width: 70%;
        margin: 10px;
    }

    .quantityRequest {
        width: 20%;
    }
}

.reportBlock {
    display: flex;
    justify-content: space-around;
    .frontBlock {
        flex-direction: column;
        display: flex;
        margin-right: 20px;

        .graphBlock {
            display: flex;
        }
    }

    .backendBlock {
        flex-direction: column;
        display: flex;
        margin-right: 20px;

        .graphBlock {
            display: flex;
        }
    }
}