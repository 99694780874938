@import "../../../styles/global";

.root {
    padding: 5px 16px;
    background-color: #fff;
    border-radius: $--border-radius;
    box-shadow: 0px 0px 10px rgba(73, 80, 87, 0.07);
    max-height: 100%;
    height: calc(100% - 100px);
    position: relative;
    z-index: 3;
}