@import "../../styles/global";
@import "../../styles/colors";

.root {
    .toolbar {
        display: flex;
        margin-bottom: 12px;
    }
}

.field{
    &_NumberLabel {
        grid-column: 6 / 9;
    }
    &_Pharmacy {
        grid-column: 1 / 6;
    }
    &_DateDoc {
        grid-column: 9 / 10;
    }
}

.filterBy {
    margin-right: 10px;
}

.autoRowSelected {
    display: inline-flex;
    background-color: $--color-grid-select;
    width: max-content;
}

.separator {
    border-radius: 30px;
    background-color: $--color-gray-250;

    &_vertical {
        width: 5px;
        margin-left: 10px;
        margin-right: 10px;
        .separator-col {
            cursor: col-resize;
        }
    }

    &_horizontal {
        height: 5px;
        margin-top: 10px;
        margin-bottom: 10px;
        margin-left: -15px;

        cursor: row-resize;
    }
}

.slimSeparator {
    border-radius: 30px;
    background-color: #dee2e6;

    &_vertical {
        width: 1px;
        margin-left: 10px;
        margin-right: 10px;
    }

    &_horizontal {
        height: 1px;
        margin-top: 10px;
        margin-bottom: 10px;
    }
}

.pluginWorkspace {
    width: 100%;
    display: flex;

    .treeView {
        max-width: 40%;
        width: auto;
        height: 100%;
        width: auto;
    }

    .gridWrapper {
        max-width: 100%;
        min-width: 10%;
        display: flex;
        flex-direction: column;
    }
}

.width300px {
    width: 300px;
}

.searchByGoodsName {
    margin: 0px 0px 0px -15px;
    position: relative;
    width: 100%;
}

.convolutionButton {
    margin-top: 3px;
    height: 32px;
    width: 30%;

    z-index: 100;
    display: flex;
    align-items: center;
    background-color: #fff;
    cursor: pointer;

    svg > path {
        stroke: #666666;
        fill: #666666;
    }

    &:hover,
    &:focus {
        background-color: transparentize($--color-primary-100, 0.9);
        stroke: $--color-primary-100;
        transition: $--transition-settings;

        .MainTooltip {
            visibility: visible;
        }

        div {
            color: #006699;
        }

        svg > path {
            stroke: #006699;
            fill: #006699;
        }
    }
}

.convolutionButton[disabled]:active, .convolutionButton[disabled], .convolutionButton[disabled]:hover {
    div {
        color: #abb5be;  
    }

    svg > path {
        stroke: #abb5be;
        fill: #abb5be;
    }
}

.settingGoodsClassifierButton {
    width: 40%;
}