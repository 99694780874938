/*corporate colors*/
$--color-corporate-100: rgb(52, 73, 94);
$--color-corporate-200: rgb(51, 122, 183);
$--color-corporate-300: rgb(179, 186, 201);
$--color-corporate-400: rgb(215, 221, 235);
$--color-corporate-500: rgb(3, 155, 179);
$--color-corporate-600: rgb(40, 48, 70);
$--color-corporate-700: rgb(22, 29, 49);

/*text colors*/
$--color-text-heading: rgb(51, 51, 51);
$--color-text-body-100: rgb(102, 102, 102);
$--color-text-body-75: rgb(126, 126, 126);
$--color-text-body-50: rgb(204, 204, 204);

/*gary color*/
$--color-gray-100: rgb(248, 249, 250);
$--color-gray-200: rgb(233, 236, 239);
$--color-gray-250: rgb(242, 243, 247);
$--color-gray-300: rgb(222, 226, 230);
$--color-gray-400: rgb(206, 212, 218);
$--color-gray-500: rgb(173, 181, 189);
$--color-gray-600: rgb(108, 117, 125);
$--color-gray-700: rgb(73, 80, 87);
$--color-gray-800: rgb(52, 58, 64);
$--color-gray-900: rgb(33, 37, 41);

/*primary color*/
$--color-primary-50: rgb(102, 153, 204);
$--color-primary-100: rgb(0, 102, 153);
$--color-primary-200: rgb(51, 102, 153);
$--color-primary-300: rgb(153, 204, 255);

/*common color*/
$--color-green-100: rgba(212, 237, 218, 1);
$--color-green-200: rgba(179, 226, 190, 1);
$--color-green-300: rgba(0, 153, 51, 1);

/*status color*/
$--color-status-success-foreground: $--color-green-300;
$--color-status-success-background: $--color-green-100;
$--color-status-success-border: $--color-green-200;
$--color-status-negative: rgb(204, 51, 51);
$--color-status-warning: rgb(255, 204, 51);
$--color-status-info: rgb(51, 153, 153);

/*grid color*/
$--color-grid-select: rgba(102, 153, 204, 0.6);
$--color-grid-hover: rgba(102, 153, 204, 0.1);

$--color-grid-danger: rgba(204, 51, 51, 0.1);
$--color-grid-gray:  rgba(114, 112, 112, 0.247);
$--color-grid-mark: #ffc001;
$--color-grid-mark-light: #fff701;
$--color-grid-font-danger: rgb(204, 51, 51);
$--color-grid-font-gray: rgb(114, 112, 112);
$--color-grid-success: rgba(41, 163, 96, 0.493);
$--color-grid-font-succcess: rgb(241, 241, 241);
