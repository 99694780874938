@import "../../../styles/global";

.wrapper {
    display: flex;
    width: 100%;
    border-bottom: 1px solid $--color-gray-300;
    position: relative;

    .panel {
        width: 100%;
        overflow: hidden;
        margin-bottom: 10px;
        display: flex;
        flex-direction: row;
        margin-bottom: -1px;
        position: relative;
        height: 28px;

        @include breakpointMedium() {
            height: 35px;
        }

        @include breakpointLarge() {
            height: 36px;
        }

        .header {
            background-color: $--color-gray-250;
            width: max-content;
            height: 100%;
            border-radius: $--border-radius $--border-radius 0px 0px;
            box-sizing: border-box;
            border: 1px solid $--color-gray-300;

            &Active {
                background-color: #fff;
                color: $--color-primary-100;
                border-bottom: none;

                path {
                    stroke: $--color-primary-100;
                }
            }

            &Disabled {
                opacity: 0.7
            }

            .content {
                margin: 5px 12px;

                @include breakpointMedium() {
                    margin: 8px 16px;
                }

                @include breakpointLarge() {
                    margin: 8px 16px;
                }

                display: flex;
                flex-direction: row;
                white-space: nowrap;

                @include agBodySmall_normal();

                @include breakpointMedium() {
                    @include agBodyMedium_normal();
                }

                @include breakpointLarge() {
                    @include agBodyLarge_normal();
                }
            }

            .closeIcon {
                width: 15px;
                height: 15px;
                margin-left: 15px;

                margin: 1px 0px 3px 8px;

                @include breakpointMedium() {
                    margin: 0.5px 0px 2.5px 8px;
                }

                @include breakpointLarge() {
                    margin: 0px 0px 2px 8px;
                }

                svg {
                    width: 13px;
                    height: 13px;

                    path {
                        stroke-width: 2;
                    }
                }
            }
        }
    }

    .switch {
        z-index: 1;
        display: flex;
        margin-left: auto;
        margin-bottom: -1px;
        padding: 0;
        list-style: none;

        .switchItem {
            background-color: $--color-gray-250;
            border: 1px solid $--color-gray-300;
            width: 36px;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;

            height: 28px;

            @include breakpointMedium() {
                height: 35px;
            }

            @include breakpointLarge() {
                height: 36px;
            }

            &:first-child {
                border-radius: 4px 0 0 0;
            }

            &:last-child {
                border-radius: 0 4px 0 0;
                border-left: none;
            }

            &:hover,
            &:focus {
                background: linear-gradient(0deg, #006699, #006699), rgba(102, 153, 204, 0.2);
                border: 1px solid #006699;

                svg>path {
                    stroke: #fff;
                }
            }
        }
    }
}