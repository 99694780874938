@import "../../../../styles/global";

.input {
  display: flex;
  align-items: center;
}

.wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .field {
    &_Name {
      grid-column: 1 / 2;
    }

    &_DateStart {
      grid-column: 2 / 3;
    }

    &_DateEnd {
      grid-column: 3 / 4;
    }

    &_Barcode {
      grid-column: 4 / 5;
    }

    &_DiscountValue {
      grid-column: 1 / 2;
    }

    &_Number {
      grid-column: 2 / 3;
    }

    &_SuperPosition {
      grid-column: 3 / 4;
    }

    &_DiscountType {
      grid-column: 4 / 5;
    }
  }
}

.numberLabel {
  width: auto - 10px;
}

.glwrapper {
  height: 100%;
}