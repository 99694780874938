@import "../../../../../../styles/global";

.field {
    &_Company {
        grid-column: 1/3;
    }
    &_SelectorRole {
        grid-column: 1/3;
    }
}

.userRolePanel {
    margin-bottom: 10px;
}

.SearchInput {
    border-radius: $--border-radius;
    background-color: $--color-corporate-700;
    padding: 8px 12px;
    border: none;
    font-size: $--font-size-16;
    line-height: $--line-height-24;
    color: #fff;
    width: 100%;

    &::placeholder {
        color: $--color-gray-300;
    }

    &:focus {
        outline: none;
        border: none;
        box-shadow: 0px 0px 0px 3.2px transparentize($--color-primary-300, 0.6);
    }

    @include before("lg") {
        font-size: $--font-size-14;
        line-height: $--line-height-20;
    }

    @include before("md") {
        font-size: $--font-size-12;
        line-height: $--line-height-18;
    }
}
