.field {
  &_Country {
    grid-column: 1/4;
  }

  &_Comment {
    grid-column: 1/4;
  }
}

.lotSelectorModal {
  width: 80%;
}
