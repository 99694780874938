.parent {
  width:100%;
  /* height:calc(100vh - var(--page-header-height) - var(--tab-button-height) - var(--tab-bottom-margin) - var(--toolbar-menu-height)); */
  height: 600px;/*ToDo*/
  background-color:#CCCCCC;
  position:relative;
}
 
.child {
  width:100%;
  height:100%;
  position:absolute;
  bottom:0px;
  right:0px;
}

.sidebar {
  width:20vw;
}

.object-property-select {
  width: 100%;
}

.sample-canvas {
  width: 100%;
  overflow: hidden;
  min-height: 100%;
  height: 100%;
}

html {
  /* --toolbar-menu-height: 3.2em; */
}

.toolbar-menu{
  /* height: var(--toolbar-menu-height); */
}

/* For rubber band selection as we don't have access to the maxGraph CSS files in the project */
div.mxRubberband {
  position: absolute;
  overflow: hidden;
  border-style: solid;
  border-width: 2px;
  border-color: #b18426;
  background: #db9b0b;
}