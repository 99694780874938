@import "../../styles/effects";

.quickLaunchPanel {
    background: #FFFFFF;
    display: flex;
    align-items: center;
    border-radius: 4px;
    position: relative;
    z-index: 4;
}

.infoWrapper {
    // /* Auto Layout */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    padding: 0px;

    position: relative;
    width: 100%;
    height: 38px;
    right: 16px;
}

.username {
    // /* Фамилия Имя Отчество */
    position: static;
    left: 17.78%;
    right: 0%;
    top: 0%;
    bottom: 50%;

    /* Body / Small / 13 / Small medium */
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 19px;
    /* identical to box height, or 146% */
    text-align: right;

    /* Gray / Gray - 700 */
    color: #495057;

    /* Inside Auto Layout */
    flex: none;
    order: 0;
    flex-grow: 0;
    margin: 1px 0px;
}

.rolename {
    // /* Администратор */
    position: static;
    left: 0%;
    right: 0%;
    top: 52.63%;
    bottom: 0%;

    /* Body/Small/Small 12 */
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 18px;
    /* identical to box height, or 150% */
    text-align: right;

    /* Gray / Gray - 600 */
    color: #6C757D;

    /* Inside Auto Layout */
    flex: none;
    order: 1;
    flex-grow: 0;
    margin: 1px 0px;
}