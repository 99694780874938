@import '/src/styles/global';

.esCheckBox {
    margin-top: 20px;
    display: flex;
    align-items: center;
}

.dateValid {
    display: inline-flex;
    background-color: $--color-grid-danger;
    color: $--color-grid-font-danger;

    &__gridRowSelected {
        display: inline-flex;
        background-color: $--color-grid-select;
        color: $--color-grid-font-danger;
    }
}