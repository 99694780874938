@import "../../styles/global";

.select {

    &__label {
        color: #666;
        margin-right: 10px;
    }

    &__custom {
        border: 1px solid $--color-gray-400;
        border-radius: 4px;
        width: 100%;
        height: 36px;
        padding: 5px 0px 5px 6px;
        margin: 8px 0px 8px 0px;
        font-family: Roboto;
        font-style: normal;
        font-weight: normal;
        color: #6c757d;
        outline: none;
        min-width: 161px;
        font-size: $--font-size-14;
        line-height: $--line-height-20;

        &Disabled {
            background-color: #e9ecef;
        }

        @include before("xs") {
            font-size: $--font-size-12;
            line-height: $--line-height-18;
        }
    }

    select:focus {
        border: 1px solid #006699;
        color: #006699;
    }
}

.selectInline {
    display: flex;
    align-items: center;
}