@import '../../../../styles/mixins';

.storeCreatorForm {
    .flex {
        &Row {
            display: flex;
            flex-direction: row;
        }

        &Col {
            display: flex;
            flex-direction: column;
        }
    }

    .field {
        &_Name {
            margin-right: 20px;
            flex: 50%;
        }

        &_Mnemocode {
            flex: 25%;
            margin-right: 20px;
        }

        &_MnemocodeEsfi {
            flex: 25%;
        }

        &_Contractor {
            flex: 50%;
            margin-right: 20px;
        }

        &_PricingPricingModel {
            flex: 50%;
        }

        &_StoreType {
            flex: 75%;
            margin-right: 20px;
        }

        &_IsDefault {
            flex: 25%;
        }

        &_Sno {
            flex-basis: 65%;
        }

        &_PriorityDistribution {
            flex: 50%;
            margin-right: 20px;
            margin-top: 10px;
        }

        &_EdmSendingType {
            flex: 50%;
            margin-top: 10px;
        }
    }
}