@import './_colors.scss';
@import './_mixins.scss';
@import './_typography.scss';
@import './_breakpoints.scss';
@import './theme.scss';


$--border-radius: 4px;
$--transition-settings: all 0.3s ease;

$--line-height-14: 14px;
$--line-height-17: 17px;
$--line-height-18: 18px;
$--line-height-19: 19px;
$--line-height-20: 20px;
$--line-height-24: 24px;
$--line-height-26: 26px;
$--line-height-28: 28px;
$--line-height-30: 30px;
$--line-height-32: 32px;
$--line-height-40: 40px;

$--font-size-12: 12px;
$--font-size-13: 13px;
$--font-size-14: 14px;
$--font-size-15: 15px;
$--font-size-16: 16px;
$--font-size-17: 17px;
$--font-size-18: 18px;
$--font-size-19: 19px;
$--font-size-20: 20px;
$--font-size-22: 22px;
$--font-size-26: 26px;
$--font-size-32: 32px;

$--font-weight-thin: 100;
$--font-weight-light: 300;
$--font-weight-regular: 400;
$--font-weight-medium: 500;
$--font-weight-bold: 700;
$--font-weight-black: 900;