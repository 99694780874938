@import '../../styles/palette';
@import '../../styles/global';

.quickAccessBar {
    display: flex;
    padding: 14px 16px;
    width: 100%;
    animation-duration: 1s;
    animation-name: slidersOpen;
    justify-content: space-between;

    .quickAccessBarBase {
        display: flex;

        .carousel {
            margin-left: 16px;
        }

        .sliderCalculator {
            position: absolute;
            z-index: 1;
            transform: translate(170%, 10%);
            width: 240px;
            height: 365px;
        }
    }
}

.quickAccessBarHidden {
    display: flex;
    padding: 14px 16px;
    animation-duration: 2s;
    animation-name: slidersClose;
    animation-fill-mode: forwards;
    justify-content: space-between;
    
    .quickAccessBarBase {
        display: flex;

        .carousel {
            margin-left: 16px;
        }
    }
}

.quickAccessBarSeparator {
    height: 36px;
    display: flex;
    justify-content: center;
    align-items: center;

    span {
        border: 1px solid $gray-400;
        border-radius: 4px;
        background-color: $gray-400;
        height: 24px;
        margin: 0px 10px;
    }
}

.calculator {
    position: absolute;
    z-index: 1;
    transform: translate(-50%, 0);
    width: 240px;
    height: 365px;
}

.esComponent {

    &_accordion {
      margin-bottom: 20px;  
    }
    &_textArea {
        height: 200px !important;
    }
}

@keyframes slidersOpen {
    from {
        margin-top: -4%;
        width: 100%;
    }

    to {
        margin-top: 0%;
        width: 100%;
    }
}

@keyframes slidersClose {
    0% {
        margin-top: 0%;
        width: 100%;
    }

    50% {
        margin-top: -5%;
        width: 100%;
    }

    100% {
        margin-top: -10%;
        opacity: 0;
        display: none;
        position: fixed;
        z-index: -5;
    }
}

.errorModalFooter {
    justify-content: center;
}