.emptySquareIcon {
    animation-duration: 0.5s;
    animation-name: visible;
    animation-fill-mode: forwards;
}

@keyframes visible {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}