.react-calculator {
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-rows: 25% 1fr;
    background-color: #f2f3f7;
    border:1px solid #f2f3f7
}

.result-panel {
    display: flex;
    flex: 2;
    border: 3px solid #f2f3f7;
    flex-direction: column;
    background-color: #fcfcfc;
    text-align: right;
    padding: 0px 1rem;
    line-height: 1.5rem;
    overflow: hidden;
}
.cur-row {
    flex: 1;
    color: hsl(214, 5%, 29%);
    font-size: 30px;
    max-width: 100%;
    background-color: inherit !important;
    border: none;
    outline: none;
    text-align: right !important;
}

.last-row {
    flex: 1;
    color: #969ba3;
    font-size: 20px;
    align-items: flex-end;
    display: flex;
    justify-content: flex-end;
    max-width: 100%;
}

.button-panel {
    flex: 5;
}

.button-panel .button {
    cursor: pointer;
    position: relative;
    margin: 0.2rem;
    padding: 0;
    border: none;
    background: #ffffff;
    border-radius: 4px;
    font-size: 20px;
    line-height: 0px;
    text-align: center;
    overflow: hidden;
}

.button:before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: #aaa;
    opacity: 0;
}

.button-panel .clicked:before {
    animation: react-calculator-click 0.5s ease-out 0s 1 alternate forwards;
}

.last {
    color: #fff;
    background-color: #006699 !important;
}

.button-panel:focus {
    outline: none;
}

@keyframes react-calculator-click {
    0% {
        transform: scale(1);
        opacity: 0.7;
    }
    100% {
        transform: scale(3);
        opacity: 0;
    }
}
