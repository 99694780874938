.store {
    grid-column: 1;
    grid-row: 1 / 3;
}

.dateNow {
    width: 50%;
}

.lotPanel {
    align-items: center;
}

.container {
    height: 100%;
    .wrapper {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .top10px {
            margin-top: 10px;
        }
    }
    .accordionWrapper {
        margin: 20px;
    }
}