@import '../../../styles/global';

$width100: 100%;

.commandsPanel {
  display: flex;
  align-items: center;
  margin-right: 10px;
}

.mainWrapper {
  width: $width100;
}
.wrapper {
  display: flex;
  width: $width100;

  @include breakpointXs() {
    flex-direction: column;
  }

  @include breakpointMedium() {
    flex-direction: column;
  }

  @include breakpointLarge() {
    flex-direction: column;
  }

  @include breakpointHd() {
    flex-direction: row;
  }
}

.searchField {
  display: flex;
  margin: -8px 0;
  align-items: center;
  
  &_SearchIcon {
    grid-column: 1/2;
    background-color: #f2f3f7;
    width: 34px;
    height: 36px;
    border-radius: 4px;
  }
  &_SearchInput {
    grid-column: 2/5;
    margin: 0 10px 0 0;
  }
  &_TypeSearch {
    grid-column: 5/9;
  }
}

.control {
  display: flex;
  background-color: $--color-gray-250;
  border-radius: $--border-radius;

  &Right {
    display: flex;
    align-items: center;
    padding: 6px;
    border-radius: $--border-radius;
  }
}

.controlDelimiter {
  display: flex;
  align-items: center;
  justify-content: center;
}

.controlPrimary {
  cursor: pointer;
  padding: 0;
  border: none;
  background: none;
  min-width: 33px;
  min-height: 33px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: $--border-radius;
  margin-left: 5px;
  margin-right: 5px;
  stroke: $--color-gray-700;
  background-color: none;
  position: relative;
  transition: $--transition-settings;

  &:last-child {
    margin-right: 0;
  }

  &:hover,
  &:focus {
    background-color: transparentize($--color-primary-100, 0.9);
    stroke: $--color-primary-100;
    transition: $--transition-settings;

    .MainTooltip {
      visibility: visible;
    }
  }

  &Negative {
    stroke: $--color-status-negative;

    &:hover,
    &:focus {
      background-color: transparentize($--color-status-negative, 0.9);
      stroke: $--color-status-negative;
    }
  }

  &Info {
    stroke: $--color-status-info;

    &:hover,
    &:focus {
      background-color: transparentize($--color-status-info, 0.9);
      stroke: $--color-status-info;
    }
  }

  &:disabled {
    cursor: default;

    path {
      stroke: $--color-corporate-300;
    }

    &:hover,
    &:focus {
      background-color: transparent;
    }
  }
}

.icon {
  width: 21px;
  height: 21px;

  &Info > path {
    stroke: $--color-status-info;

    &:hover,
    &:focus {
      background-color: transparentize($--color-status-info, 0.9);
      stroke: $--color-status-info;
    }
  }

  &Negative > path {
    stroke: $--color-status-negative;

    &:hover,
    &:focus {
      background-color: transparentize($--color-status-negative, 0.9);
      stroke: $--color-status-negative;
    }
  }
}

.delimiter {
  height: 20px;
  width: 2px;
  background-color: $--color-gray-400;
  display: block;
  margin-left: 5px;
  margin-right: 5px;
}

.field {
  &_Search {
    grid-column: 1 / 3;
    margin-left: 10px;
  }

  &_SelectBlock {
    margin-top: 8px;
    grid-column: 3/5;
  }
}
