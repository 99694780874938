@import "../../../../styles/global";

.gridRowStopList {
  display: inline-flex;
  background-color: $--color-grid-danger;
  color: $--color-grid-font-danger;

  &.gridRowSelected {
    display: inline-flex;
    background-color: $--color-grid-select;
    color: $--color-grid-font-danger;
  }
}


.field {
  &_flexRowWrapper {
    align-items: center;
    padding-left: 10px;
  }
}