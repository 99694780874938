$success-dark: #336633;
$success: #009933;
$success-light: #d4edda;

$danger-dark: #cc1135;
$danger: #cc3333;
$danger-light: #f8d7da;

$primary-dark: #003399;
$primary: #006699;
$primary-light: #cce5ff;

.title {
  cursor: pointer;
}
.hidden {
  display: none !important;
}
.visible {
  display: inline-block;
}
.alertBase {
  z-index: 10000;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 20px 5px;
  // margin: 0px 10px;
  border-radius: 4px;
  // width:100%;
}
.message {
  text-align: center;
  margin-left: 5px;
  margin-right: 5px;
  flex-grow: 1;
}
.alertInfo {
  @extend .alertBase;
  background-color: $primary-light;
  color: $primary;
}
.alertIconInfo {
  stroke: $primary;
}
.closeIconInfo {
  stroke: $primary;
  width: 12px;
  height: 12px;
  :hover {
    stroke: $primary-dark;
  }
}

.alertSuccess {
  @extend .alertBase;
  background-color: $success-light;
  color: $success;
}
.alertIconSuccess {
  stroke: $success;
}
.closeIconSuccess {
  stroke: $success;
  :hover {
    stroke: $success-dark;
  }
}
.alertDanger {
  @extend .alertBase;
  background-color: $danger-light;
  color: $danger;
}
.alertIconDanger {
  stroke: $danger;
}
.closeIconDanger {
  stroke: $danger;
  width: 12px;
  height: 12px;
  :hover {
    stroke: $danger-dark;
  }
}

.closebtn {
  // float: right;
  cursor: pointer;
  transition: 0.3s;
}
.globalBottomRight {
  position: fixed;
  // display: inline-flex;
  // flex-wrap: wrap;
  // max-height: 100%;
  // flex-direction: column-reverse;
  // align-items: flex-start;
  // flex-wrap: wrap-reverse;
  right: 0px;
  bottom: 0px;
}
