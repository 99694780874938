@import "../../../../styles/global";

.input {
  display: flex;
  align-items: center;
}

.light{

  padding: 0.4%;
  box-shadow:0px 0px 10px 0px #283046;
}
.wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .field {
    &_Provider {
      grid-column: 1 / 4;
    }
    &_Number {
      grid-column: 4 / 6;
    }
    &_Date {
      grid-column: 6 / 6;
    }
    &_BillNumber {
      grid-column: 1 / 2;
    }
    &_BillDate {
      grid-column: 2 / 3;
    }
    &_TargetProgram {
      grid-column: 3 / 5;
    }
    &_PriceModel {
      grid-column: 1 / 6;
      grid-row: 1 / 5;
    }
    &_Recalculate {
      margin-top: 6px;
      grid-column: 6 / 6;
      grid-row: 1 / 2;
    }
    &_SelectionGoods {
      grid-column: 1 / 7;
    }
    &_TypeCode {
      margin-top: 30px;
    }
    &_Empty {
      margin: 0 0 0 15px;
      min-width: 165px;

      label {
        display: none;
      }

      div {
        margin: 0 !important;
      }
    }

    &_SelectionGoods {
      width: 100%;
      margin-left: 10px;
      margin-bottom: 0 !important;
      margin-right: 15px;
    }

    &_flexblock {
      display: flex;
      align-items: center;
      margin-bottom: 28px;
    }
  }
}
.field_SelectBlock {
  margin-top: 7px;
}
.kizBarcode {
  width: 28px;
  height: 28px;
  border: 1px solid $--color-gray-400;
  display: flex;
  align-items: center;
  justify-content: center;
  background: none;
  border-radius: $--border-radius;
}
.tools {
  display: flex;
}
.SettingsButton {
  display: flex;
  width: 36px;
  height: 36px;
  background-color: $--color-gray-250;
  border: 1px solid $--color-gray-500;
  border-radius: $--border-radius;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 10px;

  button {
    width: 100%;
    height: 100%;
  }

  svg {
    padding: 0;
    width: 22px;
    height: 22px;
  }
}
.numberLabel {
  width: auto - 10px;
}
.supplierLabel {
  padding-right: 10px;
}
.farmacyLabel {
  padding-right: 40px;
}
.storeLabel {
  width: auto;
  padding-right: 18px;
}
.dateLabel {
  width: 10%;
}
.invoiceNumberLabel {
  padding-right: 15px;
}
.glwrapper {
  height: 100%;
}