$breakpoints: (
  xs: 1280px,
  md: 1440px,
  lg: 1680px,
  hd: 1920px,
  xl: 2048px,
);

@mixin breakpointXs() {
  $size: map-get($breakpoints, "xs");

  @media screen and (min-width: ($size)) {
    @content;
  }
}

@mixin breakpointMedium() {
  $size: map-get($breakpoints, "md");

  @media screen and (min-width: ($size)) {
    @content;
  }
}

@mixin breakpointLarge() {
  $size: map-get($breakpoints, "lg");

  @media screen and (min-width: ($size)) {
    @content;
  }
}

@mixin breakpointHd() {
  $size: map-get($breakpoints, "hd");

  @media screen and (min-width: ($size)) {
    @content;
  }
}

@mixin breakpointXl() {
  $size: map-get($breakpoints, "xl");

  @media screen and (min-width: ($size)) {
    @content;
  }
}

@mixin before($breakpoint) {
  $size: map-get($breakpoints, $breakpoint);

  @if ($size ==0) {
    @content;
  }

  @else {
    @media screen and (max-width: ($size - 1px)) {
      @content;
    }
  }
}

@mixin from($breakpoint) {
  $size: map-get($breakpoints, $breakpoint);

  @if ($size ==0) {
    @content;
  }

  @else {
    @media screen and (min-width: $size) {
      @content;
    }
  }
}

@mixin from-to($from, $to) {
  $min: map-get($breakpoints, $from);
  $max: map-get($breakpoints, $to) - 1px;

  @if ($min ==0) {
    @media screen and (max-width: $max) {
      @content;
    }
  }

  @else {
    @media screen and (min-width: $min) and (max-width: $max) {
      @content;
    }
  }
}