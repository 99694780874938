@import '../../styles/global';

.datePicker {
    display: flex;

    &>div>span {
        transform: translate(3%, 160%) !important;
    }

    input {
        background-color: #fff;
        border: 1px solid $--color-gray-400;
        border-right: none;
        position: relative;
        border-radius: 4px 0 0 4px;
        padding-left: 8px;
        background-image: url(../../libs/corporate/icons/img/CalendarCollection.svg);
        background-repeat: no-repeat;
        background-size: 36px 36px;
        background-position: 100% 50%;
        width: 100%;
        min-width: 130px;
    }

    input::-webkit-calendar-picker-indicator {
        cursor: pointer;
        opacity: 0;
        padding: 0;
        width: 36px;
        height: 36px;
    }

    input:focus-visible {
        outline: none;
    }

    .dateClearButton {
        background-color: $--color-gray-200;
        border: 1px solid $--color-gray-400;
        border-radius: 0 4px 4px 0;
        align-content: center;
    }

    @-moz-document url-prefix() {
        input {
            background-image: none;
            padding: 5px 5px 5px 8px;
        }
    }
}
