@import "../../styles/global";
.entityList {
    display: flex;
    flex-direction: column;
}

.border {
    height: 200px;
    width: 30%;
    overflow-y: scroll;
    border: 1px solid $--color-gray-400;
    padding: 10px;
    margin: 0 10px 10px 0;
}

.entity {
    display: flex;
    justify-content: space-between;
}
.item {
    align-self: center;
}
.buttonGroup {
    display: flex;
}
