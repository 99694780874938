@import '../../../styles/global';

.root {
    @include agBodyLarge_normal();
    height: calc(80vh - 200px);
    overflow: scroll;
    @include double-scroll-bar();

    .filterPanel {
        position: sticky;
        top: 0;
        background-color: #fff;

        .visibileButton {
            border: 1px solid $--color-gray-500;
            border-radius: $--border-radius;
            width: 33px;
            height: 33px;
            padding-left: 5px;
            padding-top: 3px;
            margin-right: 10px;
            background-color: #fff;

            svg {
                margin-right: 1px;
                margin-top: 1px;

                path {
                    stroke: $--color-gray-600;
                }
            }

            &:hover {
                border: 1px solid #99CCFF;
                box-shadow: 0px 0px 0px 3.2px rgba(0, 123, 255, 0.25);
                border-radius: 4px;
            }

            &:active {
                border: 1px solid #006699;
                border-radius: 4px;
            }
        }

        .noMargin>div {
            margin-top: 0px;
            margin-bottom: 0px;
        }

        .queryFilterInput {
            margin-top: 6px;
            padding: 6px 8px 5px;
            background: #F8F9FA;
            border: 1px solid #CED4DA;
            box-sizing: border-box;
            border-radius: 4px;
            width: 184px;
            height: 33px;
        }
    }

    .treeView {

        &>ul {
            margin: 11px 0px;
            padding: 0px;
        }

        ul {
            list-style: none;
        }

        li {
            padding: 0px;
            margin-bottom: 5px;

            button {
                padding: 0px;
            }
        }

        .svg {
            width: 16px;
            height: 16px;
            margin-right: 6px;

            &>path {
                stroke-width: 2;
            }
        }
    }

    .expandButton {
        background-color: transparent;
        margin-bottom: 12px;
        display: flex;
        cursor: pointer;

        .chevron {
            width: 16px;
            height: 16px;
            margin-right: 6px;
        }

        &:hover {
            .folderName {
                background: $--color-gray-250;
                border-radius: $--border-radius;
            }
        }
    }

    .folderName {
        padding: 2px 4px;
        text-align: left;
        white-space: nowrap;
        cursor: pointer;
    }

    .selectedFolder {
        .folderName {
            color: $--color-primary-100;
            background-color: rgba($--color-primary-50, 0.2);
            border-radius: $--border-radius;
        }

        path {
            stroke: $--color-primary-100;
        }
    }
}