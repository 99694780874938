@import '../../styles/palette';

.commandsPanel {
    display: flex;
    padding: 0 10px 0 0;
    background-color: $gray-250;
    align-items: center;
    border-radius: 4px;
}

.commandsPanelSeparator {
    height: 33px;
    display: flex;
    justify-content: center;
    align-items: center;

    span {
        border: 1px solid $gray-400;
        border-radius: 4px;
        background-color: $gray-400;
        height: 24px;
        margin: 0px 10px;
    }
}