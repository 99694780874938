@import '../../styles/global';

.workSpaceContainer {
    background-color: #F2F3F7;
    width: 100%;
    min-height: 100vh;

    &[data-burger-menu-visible='true'] {
        width: calc(100% - 250px);
        margin-left: 250px;
        min-width: 950px;

        @include before('lg') {
            width: calc(100% - 230px);
            max-width: calc(100% - 230px);
            margin-left: 230px;
        }
    }
}

.homePageWrapper {
    display: flex;
    height: 100%;
}

.workSpaceWrapper {
    padding: 0 35px 10px 35px;
    height: 100%;

    &_showTopMenu {
        display: flex;
        justify-content: end;
        padding-right: 16px;
    }
}

.toolBarWrapper {
    display: flex;
}

.tabsPanelWrapper {
    margin: 0 0 11px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &Switch {
        display: flex;
        margin: 0 0 -1px 10px;
        padding: 0;
        list-style: none;

        &Item {
            background-color: $--color-gray-250;
            border: 1px solid $--color-gray-300;
            width: 36px;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;

            height: 28px;

            @include breakpointMedium() {
                height: 35px;
            }

            @include breakpointLarge() {
                height: 36px;
            }

            &:first-child {
                border-radius: 4px 0 0 0;
            }

            &:last-child {
                border-radius: 0 4px 0 0;
                border-left: none;
            }

            &:hover,
            &:focus {
                background: linear-gradient(0deg, #006699, #006699), rgba(102, 153, 204, 0.2);
                border: 1px solid #006699;

                svg>path {
                    stroke: #fff;
                }
            }
        }
    }
}

.tabsPanelWorkarea {
    padding: 10px 16px;
    background-color: #fff;
    border-radius: $--border-radius;
    box-shadow: 0px 0px 10px rgba(73, 80, 87, 0.07);
    margin-bottom: 5px;
    margin-top: 16px;
    height: 100%;
    max-height: calc(100% - 76px);
}

.contentVisible {
    display: block;
}

.contentHidden {
    display: none;
}

.showMenuIcon {
    border-radius: 4px;
    width: 60px;
    height: 60px;
    background: #283046;
    display: flex;
    justify-content: center;
    align-items: center;
}

.tabsContainer {
    position: relative;
    overflow: hidden;
    width: 100%;
}

div.contentDisabled {
    pointer-events: none;
    opacity: 0.7;
}