@import "../../../styles/global";

.root {
    display: flex;
    width: 100%;
    border-bottom: 1px solid $--color-gray-300 ;
    border-top: 1px solid rgb(222, 226, 230);
    position: relative;

    .container {
        background-color: #FFFFFF;
        width: 100%;
        overflow: hidden;
        margin-bottom: 10px;
        display: flex;
        flex-direction: row;
        margin-bottom: -1px;
        position: relative;

        .area {
            display: flex;
            width: 100%;
            overflow: hidden;

            .items {
                width: 100%;
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
            }
        }
    }
}