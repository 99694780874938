.svg {
  width: 24px;
  height: 24px;
  fill: none;
  stroke: #495057;
  flex-shrink: 0;

  &:hover {
    stroke: #006699;
    & > svg > path {
      stroke: #006699;
    }
  }
}

.disagregation {
  opacity: 65%;
}

.svgMini {
  width: 14px;
  height: 15px;
  fill: none;
  stroke: #495057;
  flex-shrink: 0;
  &:hover {
    stroke: #006699;
    & > svg > path {
      stroke: #006699;
    }
  }
}

.loaderIcon {
  display: flex;
  justify-content: center;
}

.kizBarcode {
  width: 27px !important;
  height: 27px !important;
  fill: none;
  stroke: none !important;
  border: 1px solid #ced4da;

  /*&:hover {
    stroke: #006699;
    &>svg>path {
      stroke: #006699;
    }
  }*/ // оно нужно?
}

.fileAndFolderPlus {
  width: 21px;
  height: 21px;
  fill: none;
  stroke: #495057;

  &:hover {
    stroke: #006699;
    & > svg > path {
      stroke: #006699;
    }
  }
}
