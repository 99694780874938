.dotIndicator {
    padding: 4px;

    &__red {
        width: 8px;
        height: 8px;
        border-radius: 8px;
        background-color: red;
        align-self: center;
    }

    &__yellow {
        width: 8px;
        height: 8px;
        border-radius: 8px;
        background-color: #ecb556;
        align-self: center;
    }

    &__green {
        width: 8px;
        height: 8px;
        border-radius: 8px;
        background-color: #3aad60;
        align-self: center;
    }
}