@import '../../../../styles/palette';
@import "../../../../styles/global";


.field_SelectBlock {
    margin-top: 7px;
  }

.wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .field {
    &_CompactMode {
      grid-column: 35 / 35;
      margin-top: 8px;
    }

    &_Provider {
      grid-column: 1 / 13;
    }
  }
}

.control {
  display: flex;
  background-color: $--color-gray-250;
  border-radius: $--border-radius;

  &Right {
    display: flex;
    align-items: center;
    padding: 6px;
    border-radius: $--border-radius;
  }
}

.test {
  grid-column: 1 / 13;
  width: 350px;
}