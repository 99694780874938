
.pageFlexWrapper{
    position: fixed;
    width: 100vw;
    height: 100vh;
    display:flex;
    justify-content: center;
    align-items: center;
    background: #F2F3F7; 
    position: relative;
}