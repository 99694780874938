@import "../../styles/global";

.link {
    color: #9a9a9a;
    text-decoration-line: underline;
    font-family: Roboto;
    font-style: normal;
    font-weight: 600;

    font-size: $--font-size-14;
    line-height: $--line-height-20;

    @include before("xs") {
        font-size: $--font-size-12;
        line-height: $--line-height-18;
    }
}
