.tables {
    td {
        cursor: pointer;
    }
}

.iframe {
    width: 0px;
    height: 0px;  
    border: 0px;
    top: -100%; 
    position: absolute
}