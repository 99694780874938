@import '../../../../styles/palette';

.menuBurger {
    margin: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $leftMenu;
    border-radius: 4px;
    padding: 18px 16px;
    z-index: 3;

    svg {
        stroke: #ffffff;
    }
}