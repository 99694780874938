@import '../../../styles/global';

.root {
    position: relative;
    z-index: 3;
    &_Visible{
        display: block;
    }

    &_Hidden{
        display: none;
    }

    .wrapper {
        margin-top: 11px;
        margin-bottom: 11px;
    }
}