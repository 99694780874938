.goodsCreatorWrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    height: 100%;
}

.flexRow {
    display: flex;
    flex-direction: row;
    width: 100%;

    &_Width50 {
        width: 50%
    }
}

.flexColumn {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.marginRight {
    margin-right: 0;
}

.goodsField {
    &_Code {
        flex: 25%;
    }

    &_IsKiz {
        flex: 25%;
    }

    &_Name {
        flex: 70%;
        grid-column-start: 1;
        grid-column-end: 3;
    }

    &_Mnn {
        flex: 30%;
    }

    &_TradeName {
        flex: 33%;
    }

    &_Producer {
        flex: 33%;
    }

    &_CureForm {
        flex: 33%;
    }
}