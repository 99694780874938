@import "../../../../styles/global";
@import "../../../../styles/colors";

.filters {
    display: flex;

    .filtersField {
        margin-right: 5px;
    }

    .filtersButton {
        width: auto;
        height: 16px;
        margin-top: 28px;
        margin-right: 5px;
    }
}

.loading {
    width:100%;
    height: 10%;
    display: flex;
    justify-content: center;
}