@import "../../styles/_global.scss";

button {
    border: none;
    &:focus{
        outline: revert;
    }   
}

.btnBase {
    width: 100%;
    height: auto;
    min-height: 38px;
    font-size: 16px;
    line-height: 24px;
    border-radius: $--border-radius;
    display: flex;
    align-items: center;
    justify-content: center;
   

    &:hover {
        cursor: pointer;
    }
}

.btnPrimary {
    @extend .btnBase;
    background: $--color-primary-100;
    color: #ffffff;

    &:hover {
        background: $--color-primary-200;
    }

    &:focus {
        background: $--color-primary-50;
    }

    &:disabled {
        background: $--color-primary-50;
        opacity: 0.6;
    }
}

.btnOutPrimary {
    @extend .btnBase;
    background: #ffffff;
    color: $--color-primary-100;
    border: 1px solid $--color-primary-100;
    outline: none;

    &:hover {
        @extend .btnOutSecondaryState;
        background: rgba(84, 89, 94, 0.1);
    }

    

    &:disabled {
        @extend .btnOutSecondaryState;
        background: #ffffff;
        opacity: 0.6;
    }
}

.btnSecondary {
    @extend .btnBase;
    background: $--color-primary-100;
    color: #ffffff;

    &:hover {
        background: $--color-primary-200;
    }

    &:focus {
        background: $--color-primary-50;
    }

    &:disabled {
        background: $--color-primary-50;
        opacity: 0.6;
    }
}

.btnOutSecondaryState {
    border: 1px solid #54595e;
}

.btnOutSecondary {
    @extend .btnBase;
    background: #ffffff;
    color: #6c757d;
    border: 1px solid #6c757d;
    outline: none;

    &:hover {
        @extend .btnOutSecondaryState;
        background: rgba(84, 89, 94, 0.1);
    }

    &:focus {
        @extend .btnOutSecondaryState;
        background: rgba(84, 89, 94, 0.2);
    }

    &:disabled {
        @extend .btnOutSecondaryState;
        background: #ffffff;
        opacity: 0.6;
    }
}

.btnDanger {
    @extend .btnBase;
    background: #ffffff;
    color: $--color-status-negative;
    border: 1px solid $--color-status-negative;
    outline: none;

    &:hover {
        background: transparentize($color: $--color-status-negative, $amount: 0.9);
    }

    &:focus {
        background: transparentize($color: $--color-status-negative, $amount: 0.8);
    }

    &:disabled {
        background: white;
        opacity: 0.6;
    }
}

.spinner {
    margin-left: -20px;
    margin-right: 8px;

    @keyframes spinner {
        0% {
            transform: rotate(0deg);
        }

        100% {
            transform: rotate(359deg);
        }
    }

    display: inline-block;
    width: 20px;
    height: 20px;
    border: 0.15em solid currentColor;
    border-right-color: transparent;
    border-radius: 50%;
    animation: spinner 0.75s linear infinite;
}

.commandPanel {
    @extend .btnBase;
    background: none;
    color: #495057;

    &:hover {
        background: rgb(0 102 153 / 10%);
    }

    &:disabled {
        background: transparent;
        opacity: 0.6;
    }
}