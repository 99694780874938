@import '../../../../../styles/global';

.field {
    &_Path {
        grid-column: 1/5;
    }
    &_Name {
        grid-column: 5/7;
    }
    &_Extensions {
        grid-column: 7/10;
    }
    &_Encoding {
        grid-column: 10/13;
    }
    

    &_PayDate {
        grid-column: 1/2;
    }

    &_CodePayer {
        grid-column: 2/3;
    }

    &_Payer {
        grid-column: 3/5;
    }

    &_OneOfFour {
        grid-column: 1/2;
    }

    &_ThreeOfFour {
        grid-column: 2/5;
    }
}

.dropZone {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
    padding: 10px 10px 10px 30px;
    min-height: 200px;
    height: 100%;
    background-color: #0066991a;
    margin-bottom: 10px;
    overflow-y: auto;
}

.fileWrapper {
    margin-right: 10px;
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 10px;
    height: 95%;
    width: 20%;
    border: 6px solid white;
    border-radius: 5px;
}

.failedClassName {
    margin-right: 10px;
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 10px;
    height: 95%;
    width: 20%;
    border: 6px solid rgba(255, 0, 0, 0.384);
    border-radius: 5px;
}

.control {
    display: flex;
    background-color: $--color-gray-250;
    padding: 6px;
    border-radius: $--border-radius;
  
    &Right {
      display: flex;
      align-items: center;
      padding: 6px;
      border-radius: $--border-radius;
    }
  }

  .commandPanelDiv {
    display: flex;
  }