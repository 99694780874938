@import '../../../../../styles/global';

.root {
    display: block;
    align-self: center;
    .contentWrapper {
        //margin: 6px 16px 6px 16px;
        font-size: 14px;
        fill: none;
        color: #495057;
      
        // &:hover {
        //     color: #006699;
        //   & > svg > path {
        //     color: #006699;
        //   }
        // }
    }

    &:hover,
    &:focus {
      & > button > div {
        color: #006699;
      }
      & > button > div > svg > path {
        stroke: #006699;
      }
    }

    &_open {
        .contentWrapper {
            color: #006699;
        }
        & > button > div > svg > path {
        stroke: #006699;
        }
    }
}


@mixin modalWindow {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    position: fixed;
    background: #fff;
    box-shadow: 0px 0px 20px rgba(73, 80, 87, 0.3);
    border-radius: $--border-radius;
}

.windowWrapper {
    @include modalWindow();
    z-index: 100;
    min-width: 360px;

    &_TreeView {
        width: 400px;
    }

    &>header {
        width: 100%;
        height: 45px;
        display: flex;
        background-color: #F8F8F8;
        border-radius: 4px 4px 0 0;

        &>.headerContainer {
            display: flex;
            align-items: center;
            width: 100%;
            margin-left: 20px;
            margin-right: 12px;

            &>.title {
                font-size: $--font-size-15;
                line-height: $--line-height-24;
                font-family: Roboto;
                font-style: normal;
                font-weight: $--font-weight-medium;
                margin-right: auto;
            }

            &>button {
                background: transparent;

                &>svg {
                    margin-top: auto;
                    margin-bottom: auto;
                    display: block;
                    width: 20px;
                    height: 20px;
                }
            }
        }
    }

    &>main {
        @include scroll-bar();
        max-height: 500px;
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;


        &>.mainContainer {
            display: flex;
            flex-direction: column;
            max-width: calc(100% - 60px);
            margin-left: 30px;
            margin-right: 30px;
            margin-top: 20px;
            margin-bottom: 20px;
        }
    }

    &>footer {
        display: flex;
        width: 100%;

        &>.footerContainer {
            padding-top: 20px;
            padding-bottom: 20px;
            padding-left: 30px;
            padding-right: 30px;
            width: 100%;
            display: flex;
            flex-direction: row;
            align-self: flex-end;
            justify-content: flex-end;

            &>button {
                width: 110px;
                min-height: 28px;
                margin-right: 20px;
            }

            &>button:first-child {
                margin-right: auto;
            }

            &>button:last-child {
                margin-right: 0;
            }
        }
    }
}

.contentContainer {
    height: 500px;
    display: none;
    animation: rotateY 300ms ease-in-out forwards;
    transform-origin: top center;
    position: absolute;
    z-index: 100;
}

.button {
    perspective: 1000px;
    z-index: 100;
    display: flex;
    align-items: center;
    background-color: #fff;

    button:hover {
        background-color: transparent;

        svg:hover {
            stroke: #495057;
        }
    }
}

.button:hover,
.button:focus {
    background-color: transparentize($--color-primary-100, 0.9);
    stroke: $--color-primary-100;
    transition: $--transition-settings;

    .MainTooltip {
        visibility: visible;
    }
}

@keyframes rotateY {
    0% {
        opacity: 0;
        transform: rotateY(60px);
    }

    60% {
        transform: rotateY(-5px);
    }

    100% {
        opacity: 1;
        transform: rotateY(0px);
    }
}